import React, { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Actions from "../redux/actions"
import axios from 'axios'
import { env } from '../config'
import Loader from '../components/Loader'
import MobileAppDevelopmentQuote from "../components/MobileAppDevelopmentQuote"

const WebiteDevelopmentLPIntro = React.lazy(() => import('../components/WebiteDevelopmentLPIntro'))
const WebsiteDevelopmentLPIntroDescription = React.lazy(() => import('../components/WebsiteDevelopmentLPIntroDescription'))
const MobileAppDevelopmentServices = React.lazy(() => import('../components/MobileAppDevelopmentServices'))
const WebsiteDevelopmentLPTestimonials = React.lazy(() => import('../components/WebsiteDevelopmentLPTestimonials'))
const MobileAppDevelopmentDiscuss = React.lazy(() => import('../components/MobileAppDevelopmentDiscuss'))
const MobileAppDevelopmentGetInTouch = React.lazy(() => import('../components/MobileAppDevelopmentGetInTouch'))







function MobileAppDevelopmentLandingPage() {

    const MobileAppDevelopmentLandingData: any = useSelector((state: any) => state?.Page?.MobileAppDevelopmentLandingContent)

    const dispatch = useDispatch()

    const [showQuote, setShowQuote] = useState<boolean>(false)

    let url = "/api/mobile-app-development-content?populate=deep"
    let action = Actions.MobileAppDevelopmentLandingpageAction
    useEffect(() => {
        if (Object.keys(MobileAppDevelopmentLandingData).length === 0) {
            setTimeout(() => {
                (async () => {
                    await axios.get(`${env}${url}`)
                        .then(function (response) {
                            console.log(response.data.data.attributes);
                            dispatch(action(response?.data?.data?.attributes))

                        })
                        .catch((error: any) => {
                            console.log("error", error);
                        });
                })()
            }, 0);
        }
        window.scrollTo({
            top: 0,
            left: 1,
            behavior: 'smooth'
        });
    }, [dispatch])

    console.log("WebsiteDevelopmentLandingData", MobileAppDevelopmentLandingData)



    return (

        <Suspense fallback={<Loader />}>
            <WebiteDevelopmentLPIntro setShowQuote={setShowQuote} showQuote={showQuote} BlogPageIntro={MobileAppDevelopmentLandingData?.intro} />
            {showQuote &&
                <MobileAppDevelopmentQuote GetAQuoteSection={MobileAppDevelopmentLandingData?.GetAQuoteSection} />
            }
            <WebsiteDevelopmentLPIntroDescription imagePosition="scale-95 mb-5 lg:mb-0" ProductDevelopmentPageIntro={MobileAppDevelopmentLandingData?.IntroDescription} />
            <MobileAppDevelopmentServices Services={MobileAppDevelopmentLandingData?.Services} />
            <WebsiteDevelopmentLPIntroDescription imagePosition="mx-auto" ProductDevelopmentPageIntro={MobileAppDevelopmentLandingData?.MobileAppDevelopmentIntro} />
            <WebsiteDevelopmentLPTestimonials TestimonialSection={MobileAppDevelopmentLandingData?.MobileTestimonialSection} />
            <MobileAppDevelopmentDiscuss discuss={MobileAppDevelopmentLandingData?.Discuss} />
            <MobileAppDevelopmentGetInTouch GetInTouchSection={MobileAppDevelopmentLandingData?.GetInTouchSection} />
        </Suspense>
    )
}

export default MobileAppDevelopmentLandingPage