import React from 'react'
import { useNavigate } from 'react-router';

function WikipeedikaCardComponent(props: any) {
    const { node } = props;
    const navigate = useNavigate()

    const navigateTo = (title: string) => {
        navigate(`/wikipeedika/${title?.toLowerCase().replace(/\s+/g, '-')}`)
    }

    return (


        <article
            className="rounded-lg border border-gray-100 bg-white font-custom  p-4 shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] transition hover:shadow-lg sm:p-6"
        >
            <span className="inline-block rounded bg-[#F1E1A7] p-2 text-white">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 stroke-black-75 fill-black-75"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path d="M12 14l9-5-9-5-9 5 9 5z" />
                    <path
                        d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                    />
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                    />
                </svg>
            </span>
            <div className=' flex  items-center gap-2 py-2'>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.31699 10.0167L10.017 9.31666L7.50033 6.79999V3.66666H6.50033V7.19999L9.31699 10.0167ZM7.00033 13.3333C6.12255 13.3333 5.29766 13.1667 4.52566 12.8333C3.75321 12.5 3.08366 12.05 2.51699 11.4833C1.95033 10.9167 1.50033 10.2471 1.16699 9.47466C0.833659 8.70266 0.666992 7.87777 0.666992 6.99999C0.666992 6.12221 0.833659 5.2971 1.16699 4.52466C1.50033 3.75266 1.95033 3.08332 2.51699 2.51666C3.08366 1.94999 3.75321 1.49999 4.52566 1.16666C5.29766 0.833323 6.12255 0.666656 7.00033 0.666656C7.8781 0.666656 8.70321 0.833323 9.47566 1.16666C10.2477 1.49999 10.917 1.94999 11.4837 2.51666C12.0503 3.08332 12.5003 3.75266 12.8337 4.52466C13.167 5.2971 13.3337 6.12221 13.3337 6.99999C13.3337 7.87777 13.167 8.70266 12.8337 9.47466C12.5003 10.2471 12.0503 10.9167 11.4837 11.4833C10.917 12.05 10.2477 12.5 9.47566 12.8333C8.70321 13.1667 7.8781 13.3333 7.00033 13.3333ZM7.00033 12.3333C8.4781 12.3333 9.73655 11.814 10.7757 10.7753C11.8143 9.73621 12.3337 8.47777 12.3337 6.99999C12.3337 5.52221 11.8143 4.26377 10.7757 3.22466C9.73655 2.18599 8.4781 1.66666 7.00033 1.66666C5.52255 1.66666 4.26433 2.18599 3.22566 3.22466C2.18655 4.26377 1.66699 5.52221 1.66699 6.99999C1.66699 8.47777 2.18655 9.73621 3.22566 10.7753C4.26433 11.814 5.52255 12.3333 7.00033 12.3333Z" fill="#8A8A8A" />
                </svg>
                <div className='text-xs font-custom text-[#8A8A8A]'>
                    {node?.attributes?.Date}
                </div>
            </div>

            <h3 className="mt-0.5 text-lg font-medium text-black-75">
                {node?.attributes?.Title}
            </h3>


            <p className="mt-2 cardClampCareerCard text-sm/relaxed text-gray-500">
                {node?.attributes?.Overview?.filter((node: any, index: number) => index < 1).map((node: any) => {
                    return node?.Paragraph
                })}
            </p>

            <div role="button" onClick={() => navigateTo(node?.attributes?.Title)} className="group mt-4 inline-flex items-center gap-1 text-sm font-medium text-[#f7941e]">
                Find out more

                <span aria-hidden="true" className="block transition-all group-hover:ms-0.5 rtl:rotate-180">
                    &rarr;
                </span>
            </div>
        </article>

    )
}

export default WikipeedikaCardComponent