import axios from 'axios';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { env } from '../config';
import { useLocation } from 'react-router';
import Loader from '../components/Loader';


export interface Attributes {
    title: string
    description: string
    createdAt: string
    updatedAt: string
    publishedAt: string
    page: string
    description2: any
}
const NcsContact = () => {


    const emptyState = {
        firstName: "",
        lastName: "",
        companyName: "",
        designation: "",
        date: "",
        email: "",
        message: ""
    };

    const location = useLocation()

    type formType = typeof emptyState

    const [formData, setFormData] = useState(emptyState);
    const [flag, setFlag] = useState("initial");
    const emailRegex = /^\S+@\S+\.\S+$/


    const handleForm = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        let copyFormData = { ...formData }
        console.log("eeeee", e.target?.value)
        copyFormData = { ...copyFormData, [e?.target?.id]: e.target.value }

        setFormData(copyFormData)
        console.log("formdata", copyFormData)
    }

    const url = location?.pathname?.split("/")[1] === "ncs" ? "/api/ncs-inquiries" : "/api/paystratum-inquiries"
    const sendMail = async () => {
        setFlag("loading")
        if (emailRegex.test(formData?.email)) {
            const nullCheck = Object.keys(formData).map((key) => {
                if (formData[key as keyof formType] === null || formData[key as keyof formType] === undefined || formData[key as keyof formType].length === 0) {
                    return key
                }
                return null
            }).filter((node) => { return node !== null })
            console.log("nullCheck", nullCheck, formData)
            if (nullCheck?.length !== 0) {
                setFlag("notcomplete")

            } else {
                console.log("hereee", new Date(formData?.date).toDateString() + " " + new Date(formData?.date).toTimeString())
                await axios
                    .post(`${env}${url}`, {
                        data: { ...formData, date: new Date(formData?.date).toDateString() + " " + new Date(formData?.date).toTimeString() },
                    }).then((response) => {
                        setFlag("success")
                        setFormData(emptyState)
                        setTimeout(() => {
                            setFlag("initial")
                        }, 4000);
                    }).catch((error) => {
                        setFlag("error")
                    })
            }

        } else {
            setFlag("notcomplete")

        }
    }

    const InquiryPageUrl = "/api/inquiry-pages/"

    const [pageData, setPageData] = useState<undefined | Attributes>()

    useEffect(() => {

        axios.get(`${env}${InquiryPageUrl}`)
            .then(function (response) {
                console.log("response?.data", location?.pathname?.split("/")[1]);
                const currentPage = response?.data?.data.filter((node: { id: number, attributes: Attributes }) => {
                    return node?.attributes?.page === location?.pathname?.split("/")[1]
                })[0]?.attributes
                console.log("currentPage", currentPage)
                setPageData(currentPage)
            })
            .catch((error: any) => {
                console.log("error", error);
            });


        window.scrollTo({
            top: 0,
            left: 1,
        });
    }, [])


    const minDate = () => {
        // Get the date in ISO 8601 format (UTC)
        const isoString = new Date().toISOString();

        // Extract date and time components
        const yyyyMMdd = isoString.slice(0, 10); // Format: "YYYY-MM-DD"
        const hhmm = isoString.slice(11, 16); // Format: "hh:mm"

        // Combine date and time components
        const formattedDate = `${yyyyMMdd}T${hhmm}`;

        return formattedDate;
    };


    useEffect(() => {
        switch (flag) {
            case "error":
                toast.error("There seems to be some error . Please try again")
                break;
            case "notcomplete":
                toast.error("Incomplete form. Please fill all fields to submit")

                break;
            case "success":
                toast.success("Thank you for your submission. We'll be in touch shortly with more information.")
                break;
            default:

                break;
        }
    }, [flag])


    return (
        pageData ?
            <div className='px-8 py-7 lg:px-10 xl:px-20 lg:py-14 xl:py-24 w-screen  bg-[#fef2e4]   grid place-content-center text-black-75 '>
                <div className='max-w-[1024px]'>
                    <div className={` text-2xl md:text-4xl lg:text-5xl text-center  font-custom xl:!leading-[1.2em] font-bold`}>
                        {pageData?.title}
                    </div>
                    <div className={`lg:text-lg md:text-base  text-center font-normal pt-5`}>
                        {pageData?.description}
                    </div>
                    {pageData?.description2 &&
                        <div className={`lg:text-lg md:text-base  text-center font-normal pt-5`}>
                            {pageData?.description2}
                        </div>
                    }

                    <div className='md:grid  md:grid-cols-2 gap-3 my-14 bg-white  p-8 rounded-md '>
                        <div className='my-2 md:my-0'>
                            <div className='text-[#868686] pb-2'>
                                First Name
                            </div>
                            <input
                                required
                                onChange={(e) => {
                                    handleForm(e);
                                    setFlag("initial");
                                }}
                                value={formData?.firstName}

                                id="firstName" type="text" placeholder='First name' className={` border border-[#404041] text-black-75 p-2  placeholder-[lightgray] w-full   rounded-[10px]`} />
                        </div>
                        <div className='my-2 md:my-0'>
                            <div className='text-[#868686] pb-2'>
                                Last Name
                            </div>
                            <input
                                required
                                onChange={(e) => {
                                    handleForm(e);
                                    setFlag("initial");
                                }}
                                value={formData?.lastName}

                                id="lastName" type="text" placeholder='Last name' className={` border border-[#404041] text-black-75 p-2  placeholder-[lightgray] w-full   rounded-[10px]`} />
                        </div>
                        <div className='my-2 md:my-0'>
                            <div className='text-[#868686] pb-2'>
                                Company Name
                            </div>
                            <input
                                required
                                onChange={(e) => {
                                    handleForm(e);
                                    setFlag("initial");
                                }}
                                value={formData?.companyName}

                                id="companyName" type="text" placeholder='Company name' className={` border border-[#404041] text-black-75 p-2  placeholder-[lightgray] w-full   rounded-[10px]`} />
                        </div>
                        <div className='my-2 md:my-0'>
                            <div className='text-[#868686] pb-2'>
                                Business Email
                            </div>
                            <input
                                required
                                onChange={(e) => {
                                    handleForm(e);
                                    setFlag("initial");
                                }}
                                value={formData?.email}

                                id="email" type="text" placeholder='example@gmail.com' className={` border border-[#404041] text-black-75 p-2  placeholder-[lightgray] w-full   rounded-[10px]`} />
                        </div>
                        <div className='my-2 md:my-0'>
                            <div className='text-[#868686] pb-2'>
                                Designation
                            </div>
                            <input
                                required
                                onChange={(e) => {
                                    handleForm(e);
                                    setFlag("initial");
                                }}
                                value={formData?.designation}

                                id="designation" type="text" placeholder='Designation' className={` border border-[#404041] text-black-75 p-2  placeholder-[lightgray] w-full   rounded-[10px]`} />
                        </div>
                        <div className='my-2 md:my-0'>
                            <div className='text-[#868686] pb-2'>
                                Preferred Time To Meet
                            </div>
                            <input id="date" min={minDate()} required value={formData?.date} onChange={(e) => {
                                handleForm(e);
                                setFlag("initial");
                            }} className=' border font-sans border-[#404041] text-black-75 p-2  placeholder-[lightgray] w-full   rounded-[10px]' aria-label="Date and time" type="datetime-local" />

                        </div>
                        <div className='col-span-2 my-2 md:my-0'>
                            <div className='text-[#868686] pb-2'>
                                How may we help you?
                            </div>
                            <textarea
                                required
                                onChange={(e) => {
                                    handleForm(e);
                                    setFlag("initial");
                                }}
                                value={formData.message}
                                id="message" placeholder='Describe your message here ' className='resize-none border border-[#404041] text-black-75 p-2  placeholder-[lightgray] rounded-[10px]  w-full h-[150px]'>

                            </textarea>
                        </div>
                        <div className='flex justify-end col-span-2 my-2'>

                            <button onClick={sendMail} className='mx-auto md:mx-0 bg-[#616161] text-white border border-[#616161] hover:bg-white hover:text-[#616161] hover:border hover:border-[#616161] rounded-[10px] px-4 py-2' >Submit </button>
                        </div>

                    </div>

                </div>


            </div>
            : <Loader />




    )
}

export default NcsContact