import React, { useState } from 'react'
import { env } from '../config';
import axios from 'axios';

function MobileAppDevelopmentQuote(props: any) {
    const { GetAQuoteSection } = props

    interface formType {
        name: string;
        number: string;
        email: string;
        companyName: string;
        requirements: string;

    }

    const defaultData = {
        name: "",
        number: "",
        email: "",
        companyName: "",
        requirements: ""
    }
    const [formData, setformData] = useState<formType>(defaultData)
    const [flag, setFlag] = useState("initial")

    const url = "/api/mobile-app-develeopmen-page-emails"
    const numberRegex = /^\d*$/

    const emailRegex = /^\S+@\S+\.\S+$/

    const handleForm = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        let copyFormData = { ...formData }
        let numberCheck: boolean

        numberCheck = numberRegex.test(e.target.value)


        if (e?.target?.id === "number" && numberCheck) {
            copyFormData = { ...copyFormData, [e?.target?.id]: e.target.value }
        }
        if (e?.target.id !== "number") {
            copyFormData = { ...copyFormData, [e?.target?.id]: e.target.value }

        }
        setformData(copyFormData)
        console.log("formdata", copyFormData)
    }

    const sendMail = async () => {
        setFlag("loading")
        if (emailRegex.test(formData?.email)) {
            const nullCheck = Object.keys(formData).map((key) => {
                if (formData[key as keyof formType] === null || formData[key as keyof formType] === undefined) {
                    return key
                }
                return null
            }).filter((node) => { return node !== null })
            console.log("nullCheck", nullCheck)
            if (nullCheck.length !== 0) {
                setFlag("notcomplete")

            } else {
                await axios
                    .post(`${env}${url}`, {
                        data: formData,
                    }).then((response) => {
                        setFlag("success")
                        setTimeout(() => {
                            setFlag("initial")
                            setformData(defaultData)
                        }, 5000);
                    }).catch((error) => {
                        setFlag("error")
                    })
            }

        } else {
            setFlag("notcomplete")
        }
    }
    return (
        <div id="getInTouch" className='px-5 py-7 lg:px-10 xl:px-20 lg:py-14 text-black-75 xl:mx-auto max-w-7xl '>
            <div className="text-2xl md:text-4xl lg:text-5xl text-left  mb-5  leading-loose  font-custom font-bold">
                {GetAQuoteSection?.Title}
            </div>
            <div className="lg:text-lg md:text-base  font-normal  mb-3 pb-5  text-left">
                {GetAQuoteSection?.Description}
            </div>
            <div className='flex flex-col lg:grid lg:grid-cols-2 '>
                <div className='py-2'>
                    <div className='text-[#868686] pb-2'>
                        Name
                    </div>
                    <input
                        onChange={(e) => {
                            handleForm(e);
                            setFlag("initial");
                        }}
                        required
                        type="text" id="name" value={formData?.name} placeholder="Name" className={` p-2 border text-black-75 border-[#404041] w-full lg:w-11/12  rounded-[10px] placeholder-[lightgray]`}></input>

                </div >
                <div className='py-2'>
                    <div className='text-[#868686] pb-2'>
                        Email Address
                    </div>
                    <input
                        onChange={(e) => {
                            handleForm(e);
                            setFlag("initial");
                        }}
                        required
                        type="text" id="email" value={formData?.email} placeholder="example@gmail.com" className={` p-2 border text-black-75 border-[#404041] w-full lg:w-11/12  rounded-[10px] placeholder-[lightgray]`}></input>

                </div >
                <div className='py-2'>
                    <div className='text-[#868686] pb-2'>
                        Phone Number
                    </div>
                    <input
                        onChange={(e) => {
                            handleForm(e);
                            setFlag("initial");
                        }}
                        required
                        type="text" id="number" value={formData?.number} placeholder="Eg: +1 8732946478" className={` p-2 border text-black-75 border-[#404041] w-full lg:w-11/12  rounded-[10px] placeholder-[lightgray]`}></input>

                </div >
                <div className='py-2'>
                    <div className='text-[#868686] pb-2'>
                        Company Name
                    </div>
                    <input
                        onChange={(e) => {
                            handleForm(e);
                            setFlag("initial");
                        }}
                        required
                        type="text" id="companyName" value={formData?.companyName} placeholder="Example Technologies Private Limited" className={` p-2 border text-black-75 border-[#404041] w-full lg:w-11/12  rounded-[10px] placeholder-[lightgray]`}></input>

                </div >
                <div className='py-2 col-span-2'>
                    <div className='text-[#868686] pb-2'>
                        Requirements
                    </div>
                    <textarea
                        onChange={(e) => {
                            handleForm(e);
                            setFlag("initial");
                        }}
                        required
                        id="requirements" value={formData?.requirements} placeholder="type here..." className={` p-2 border text-black-75 border-[#404041] w-full lg:w-[96%] h-[250px] rounded-[10px] placeholder-[lightgray]`}></textarea>

                </div >
                <button onClick={sendMail} className=" bg-[#616161] col-span-2 mr-10 justify-self-end text-white border border-[#616161] hover:bg-white hover:text-[#616161] hover:border hover:border-[#616161] rounded-[50px] w-max px-4 py-2 my-6">Submit</button>

            </div>
            <div className="  flex  ">
                {flag === "success" && (
                    <div className='bg-transparent duration-700 backdrop-brightness-50 p-5  backdrop-blur-sm flex justify-center items-center w-screen h-screen fixed top-0 left-0 z-[999999] '>
                        <div className='bg-white lg:w-[500px] h-max p-10 flex flex-col justify-center items-center relative shadow-md rounded-md'>
                            <button onClick={() => setFlag("initial")} type="button" className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="successModal">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className='flex items-center justify-center gap-6 '>
                                <div className="w-10 h-10 rounded-full bg-green-100 d0 p-2 flex items-center justify-center">
                                    <svg aria-hidden="true" className="w-8 h-8 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                    <span className="sr-only">Success</span>
                                </div>
                                <div className='font-bold font-custom text-black-75'>
                                    Thank you for your submission. We'll be in touch shortly with more information.
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {flag === "error" && (
                    <div className="flex flex-col bg-white text-[#EE5F5F] w-full mx-auto p-2 md:p-3 border-[#EE5F5F] border-2 rounded-md mt-2 font-semibold md:text-sm text-xs">
                        Error
                        <div className=" py-1 md:py-2 text-[#EE5F5F] font-normal">
                            There seems to be some error . Please try again
                        </div>
                    </div>
                )}
                {flag === "notcomplete" && (
                    <div className="flex flex-col bg-white text-blue-500 w-full mx-auto p-2 md:p-3 border-blue-500 border-2 rounded-md mt-2  font-semibold md:text-sm text-xs">
                        Incomplete form
                        <div className=" py-1 md:py-2 text-blue-500 font-normal">
                            Please fill all fields to submit
                        </div>
                    </div>
                )}
            </div>

        </div>
    )
}

export default MobileAppDevelopmentQuote