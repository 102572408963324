import axios from 'axios';
import React, { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import BlogIntro from '../components/BlogIntro'
import { env } from '../config';
import * as Actions from "../redux/actions"
import Loader from '../components/Loader';
const BlogCardSection = React.lazy(() => import('../components/BlogCardSection'))


function BlogPage() {
    const dispatch = useDispatch()

    const BlogData: any = useSelector((state: any) => state?.Page?.BlogPageContent)
    const BlogPages: any = useSelector((state: any) => state?.Page?.BlogContent)



    let url = "/api/blog-page-content?populate=deep"
    let action = Actions.BlogPageAction
    let url1 = "/api/blogs?populate=deep"
    let action1 = Actions.BlogContentAction



    useEffect(() => {

        window.scrollTo({
            top: 0,
            left: 1,
            behavior: 'smooth'
        });



        if (Object.keys(BlogData).length === 0) {
            setTimeout(() => {
                (async () => {
                    await axios.get(`${env}${url}`)
                        .then(function (response) {
                            console.log(response.data.data.attributes);
                            dispatch(action(response?.data?.data?.attributes))

                        })
                        .catch((error: any) => {
                            console.log("error", error);
                        });
                })()
            }, 0);
        }
        if (Object.keys(BlogPages).length === 0) {
            setTimeout(() => {
                (async () => {
                    await axios.get(`${env}${url1}`)
                        .then(function (response) {
                            dispatch(action1(response?.data?.data))

                        })
                        .catch((error: any) => {
                            console.log("error", error);
                        });
                })()
            }, 0);
        }
        window.scrollTo({
            top: 0,
            left: 1,
            behavior: 'smooth'
        });
    }, [dispatch])





    console.log("HOOMMRR", BlogData);


    console.log("BlogContent", BlogPages)


    return (
        <div>
            <Suspense fallback={<Loader />}>
                <BlogCardSection BlogPageIntro={BlogData?.BlogPageIntro} BlogPages={BlogPages} />
            </Suspense>
        </div>
    )
}

export default BlogPage



