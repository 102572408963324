import React, { Suspense, useEffect, useState } from 'react'
// import HoolvaTelcom from '../components/HoolvaTelcom'
import VoifinityFeaturesTelcom from '../components/VoifinityFeaturesTelcom'
import Vosavixytelecom from '../components/Vosavixytelecom'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { env } from '../config'
import * as Actions from '../redux/actions'
import Loader from '../components/Loader'
import VoinfinityTelcom from '../components/VoinfinityTelcom'
import HRMnestSection from '../components/HRMnestSection'
import DigitalTransformationdescription from '../components/FohikSection'
import ContactUsSection from '../components/ContactUsSection'


function TelecomPage() {


    const TelecomData: any = useSelector((state: any) => state?.Page?.TelecomPageContent)
    console.log("HOOMMRR", TelecomData?.section);
    const dispatch = useDispatch()







    useEffect(() => {



        setTimeout(() => {
            (async () => {
                await axios
                    .get(`${env}/api/product-page-content?populate=deep`)
                    .then(async (response: any) => {
                        console.log("hjhbjh", response)

                        dispatch(Actions.TelecomPageAction(response?.data?.data?.attributes))

                    })
                    .catch((error: any) => {
                        console.log("error", error);
                    });
            })()

        }, 0);


        window.scrollTo({
            top: 0,
            left: 1,
            behavior: 'smooth'
        });



    }, [dispatch])


    return (

        Object.keys(TelecomData)?.length !== 0 ?


            <>



                {TelecomData?.section?.map((node: any, index: number) => {
                    console.log("asdkhbja", node)
                    if (node?.product?.toLowerCase() === "ncs") {
                        return <VoinfinityTelcom key={index + node?.product} TelecomVoifinitySection={TelecomData?.section?.filter((node: { product: string }) => node?.product.toLowerCase() === "ncs")[0]} />
                    } else if (node?.product?.toLowerCase() === "fohik") {
                        return <DigitalTransformationdescription key={index + node?.product} DigitalTransformationDescription={node} Cards={node?.ProductCard} />

                    } else {
                        return <HRMnestSection key={index + node?.product} product={node} />

                    }


                })}
                <ContactUsSection ContactUsSection={TelecomData?.ConnectSection} />
            </>


            : <Loader />






    )
}

export default TelecomPage