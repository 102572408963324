import React, { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Actions from "../redux/actions"
import axios from 'axios'
import { env } from '../config'
import Loader from '../components/Loader'
const ProductdevelopmentIntro = React.lazy(() => import('../components/ProductdevelopmentIntro'))
const ItConsultingHomePartnersSection = React.lazy(() => import('../components/ItConsultingHomePartnersSection'))
const ItConsultingHomeCardsSection = React.lazy(() => import('../components/ItConsultingHomeCardsSection'))
const ItConsultingHomeBusinessCardsSection = React.lazy(() => import('../components/ItConsultingHomeBusinessCardsSection'))
const ContactUsSection = React.lazy(() => import('../components/ContactUsSection'))



function ItConsultingHomePage() {
    const ItConsultingHomeData: any = useSelector((state: any) => state?.Page?.ItConsultingHomePageContent)

    const dispatch = useDispatch()


    let url = "/api/it-consulting-home-page-content?populate=deep"
    let action = Actions.ItConsultingHomepageAction




    useEffect(() => {
        if (Object.keys(ItConsultingHomeData).length === 0) {
            setTimeout(() => {
                (async () => {
                    await axios.get(`${env}${url}`)
                        .then(function (response) {
                            console.log(response.data.data.attributes);
                            dispatch(action(response?.data?.data?.attributes))

                        })
                        .catch((error: any) => {
                            console.log("error", error);
                        });
                })()
            }, 0);
        }

        window.scrollTo({
            top: 0,
            left: 1,
            behavior: 'smooth'
        });
    }, [dispatch])

    return (
        <div>
            <Suspense fallback={<Loader />}>

                <ProductdevelopmentIntro imagePosition="mx-auto" ProductDevelopmentPageIntro={ItConsultingHomeData?.IntroContent} altText="IT
Consulting" />

                <ItConsultingHomePartnersSection IoTPartners={ItConsultingHomeData?.PartnersSection} altText="EMQX,Vitria" />

                <ItConsultingHomeCardsSection gridValues="grid lg:grid-cols-3 md:grid-cols-2  " CardsSection={ItConsultingHomeData?.CardsSection} />

                <ItConsultingHomeBusinessCardsSection Features={ItConsultingHomeData?.BusinessOppurtutinitiesSection?.CardsSection} title={ItConsultingHomeData?.BusinessOppurtutinitiesSection?.Title} />

                <ContactUsSection ContactUsSection={ItConsultingHomeData?.Contactus} />
            </Suspense>




        </div>
    )
}

export default ItConsultingHomePage


