import React, { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Actions from "../redux/actions"
import axios from 'axios'
import { env } from '../config'
import Loader from '../components/Loader'

const WebiteDevelopmentLPIntro = React.lazy(() => import('../components/WebiteDevelopmentLPIntro'))
const WebsiteDevelopmentLPIntroDescription = React.lazy(() => import('../components/WebsiteDevelopmentLPIntroDescription'))
const WebsiteDevelopmentLPTestimonials = React.lazy(() => import('../components/WebsiteDevelopmentLPTestimonials'))
const WebsiteDevelopmentContactSection = React.lazy(() => import('../components/WebsiteDevelopmentContactSection'))
const WebsiteDevelopmentLPWhyChooseSection = React.lazy(() => import('../components/WebsiteDevelopmentLPWhyChooseSection'))
const WebsiteDevelopmentLPContactCard = React.lazy(() => import('../components/WebsiteDevelopmentLPContactCard'))
const WebsiteDevelopmentLandingPageAboutUs = React.lazy(() => import('../components/WebsiteDevelopmentLandingPageAboutUs'))
const DataAnalyticsCards = React.lazy(() => import('../components/DataAnalyticsCards'))










function WebsiteDevelopmentLandingPage() {
    const WebsiteDevelopmentLandingData: any = useSelector((state: any) => state?.Page?.WebsiteDevelopmentLandingContent)

    const dispatch = useDispatch()

    const [showQuote, setShowQuote] = useState<boolean>(false)

    let url = "/api/website-development-landing-page?populate=deep"
    let action = Actions.WebsiteDevelopmentLandingpageAction
    useEffect(() => {
        if (Object.keys(WebsiteDevelopmentLandingData).length === 0) {
            setTimeout(() => {
                (async () => {
                    await axios.get(`${env}${url}`)
                        .then(function (response) {
                            console.log(response.data.data.attributes);
                            dispatch(action(response?.data?.data?.attributes))

                        })
                        .catch((error: any) => {
                            console.log("error", error);
                        });
                })()
            }, 0);
        }
        window.scrollTo({
            top: 0,
            left: 1,
            behavior: 'smooth'
        });
    }, [dispatch])




    console.log("WebsiteDevelopmentLandingData", WebsiteDevelopmentLandingData)

    return (
        <Suspense fallback={<Loader />}>

            <WebiteDevelopmentLPIntro showQuote={showQuote} setShowQuote={setShowQuote} BlogPageIntro={WebsiteDevelopmentLandingData?.Intro} />
            {showQuote &&
                <WebsiteDevelopmentContactSection />
            }
            <WebsiteDevelopmentLPIntroDescription ProductDevelopmentPageIntro={WebsiteDevelopmentLandingData?.IntroDescription} />
            <DataAnalyticsCards ButtonText2={WebsiteDevelopmentLandingData?.CardsSection?.ButtonText2} ButtonText1={WebsiteDevelopmentLandingData?.CardsSection?.ButtonText1} title={WebsiteDevelopmentLandingData?.CardsSection?.Title} Description={WebsiteDevelopmentLandingData?.CardsSection?.Description} Features={WebsiteDevelopmentLandingData?.CardsSection?.Cards} />
            <WebsiteDevelopmentLPWhyChooseSection WhyChooseSection={WebsiteDevelopmentLandingData?.WhyChooseSection} />
            <WebsiteDevelopmentLPContactCard ContactCard={WebsiteDevelopmentLandingData?.ContactCard} />
            <WebsiteDevelopmentLPTestimonials TestimonialSection={WebsiteDevelopmentLandingData?.TestimonialSection} />
            <WebsiteDevelopmentLandingPageAboutUs AboutCard={WebsiteDevelopmentLandingData?.AboutCard} />
            <WebsiteDevelopmentContactSection />
        </Suspense>
    )
}

export default WebsiteDevelopmentLandingPage