import React, { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { env } from '../config'
import * as Actions from "../redux/actions"
import Loader from '../components/Loader'
import { useLocation } from 'react-router'
const CompanyPageIntro = React.lazy(() => import('../components/CompanyPageIntro'))
const Capabilities = React.lazy(() => import('../components/CapabilitiesHome'))
const Different = React.lazy(() => import('../components/DifferentHome'))
const History = React.lazy(() => import('../components/HistoryHome'))
const ContactUsSection = React.lazy(() => import('../components/ContactUsSection'))




function CompanyPage() {
    const CompanyData: any = useSelector((state: any) => state?.Page?.CompanyPageContent)

    const dispatch = useDispatch()


    let url = "/api/company-page-content?populate=deep"
    let action = Actions.CompanypageAction



    useEffect(() => {
        if (Object.keys(CompanyData).length === 0) {
            setTimeout(() => {
                (async () => {
                    await axios.get(`${env}${url}`)
                        .then(function (response) {
                            console.log(response.data.data.attributes);
                            dispatch(action(response?.data?.data?.attributes))

                        })
                        .catch((error: any) => {
                            console.log("error", error);
                        });
                })()
            }, 0);
        }
        window.scrollTo({
            top: 0,
            left: 1,
            behavior: 'smooth'
        });
    }, [dispatch])

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "http://localhost:1337/plugins/strapi-stripe/static/stripe.js";
        script.async = true;
        document.head.appendChild(script);
    }, [])

    function SS_ProductCheckout(productId: any, baseUrl: any, userEmail: any) {
        localStorage.setItem('strapiStripeUrl', baseUrl);
        const getRedirectUrl = baseUrl + '/strapi-stripe/getRedirectUrl/' + productId + '/' + userEmail;

        fetch(getRedirectUrl, {
            method: 'get',
            mode: 'cors',
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
        })
            .then(response => response.json())
            .then(response => {
                if (response.url) {
                    window.location.replace(response.url);
                }
            });
    }


    useEffect(() => {
        const ssProduct = document.querySelectorAll('.SS_ProductCheckout');


        if (ssProduct) {
            ssProduct.forEach(product => {
                product.addEventListener('click', function handleClick(event: any) {
                    SS_ProductCheckout(
                        event.target.dataset.id,
                        event.target.dataset.url,
                        event.target.dataset.email
                    );
                });
            });
        }

    }, [])


    useEffect(() => {
        const params = new URLSearchParams(document.location.search);

        console.log("resultt", params)
    }, [])




    return (
        <>
            <Suspense fallback={<Loader />}>

                <CompanyPageIntro ContactPageIntro={CompanyData?.CompanyPageIntro} />

                <Capabilities HomeIntroContent={CompanyData?.CompanyPageIntroDescription} />

                <Different HomeFeatures={CompanyData?.CompanyFeatures} />

                <History HomeHistorySection={CompanyData?.CompanyHistorySection} />

                <ContactUsSection ContactUsSection={CompanyData?.ConnectSection} />
            </Suspense>


        </>
    )
}

export default CompanyPage