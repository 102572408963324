import React, { Suspense, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import axios from 'axios'
import { env } from '../config'
import * as Actions from "../redux/actions"
import Loader from '../components/Loader'
import HeadingText from '../components/HeadingText'
import HRMnestTitleSection from '../components/HRMnestTitleSection'
import HRMnestSection from '../components/HRMnestSection'
const ProductdevelopmentIntro = React.lazy(() => import('../components/ProductdevelopmentIntro'))
const DigitalTransformationdescription = React.lazy(() => import('../components/DigitalTransformationdescription'))
const DigitalTranformationTechCards = React.lazy(() => import('../components/DigitalTranformationTechCards'))
const DigitalTranformationAdv = React.lazy(() => import('../components/DigitalTranformationAdv'))
const ContactUsSection = React.lazy(() => import('../components/ContactUsSection'))





function FinancePage() {
    const FinanceData: any = useSelector((state: any) => state?.Page?.FinancePageContent)

    const dispatch = useDispatch()


    let url = "/api/fintech-page-content?populate=deep"
    let action = Actions.FinancepageAction




    useEffect(() => {
        if (Object.keys(FinanceData).length === 0) {
            setTimeout(() => {
                (async () => {
                    await axios.get(`${env}${url}`)
                        .then(function (response) {
                            console.log(response.data.data.attributes);
                            dispatch(action(response?.data?.data?.attributes))

                        })
                        .catch((error: any) => {
                            console.log("error", error);
                        });
                })()
            }, 0);
        }
        window.scrollTo({
            top: 0,
            left: 1,
            behavior: 'smooth'
        });
    }, [dispatch])



    return (
        <div>
            <Suspense fallback={<Loader />} >
                <ProductdevelopmentIntro imagePosition="mx-auto" altText="Fintech+Paytech" ProductDevelopmentPageIntro={FinanceData?.FintechPageIntro} />
                <DigitalTransformationdescription Cards={FinanceData?.FintechDescription?.DigitalTransformationCards} DigitalTransformationDescription={FinanceData?.FintechDescription} />
                <DigitalTranformationTechCards DigitalTransformationTechCards={FinanceData?.FintechCards} />
                <DigitalTranformationAdv DigitalTransformationCardsSection={FinanceData?.FintechCardsSection} />
                <div className='  text-black-75 px-5 py-7 lg:px-10 xl:px-20 lg:py-14 font-custom text-center lg:w-3/5 lg:mx-auto'>
                    <HeadingText Heading={FinanceData?.projects?.filter((node: any, index: number) => index < 1)[0]?.product} Body={FinanceData?.projects?.filter((node: any, index: number) => index < 1)[0]?.description} />
                </div>
                {FinanceData?.projects?.filter((node: any, index: number) => index > 0).map((child: any, index: number) => {
                    return <HRMnestSection product={child} bg={true} index={index} />
                })}
                <ContactUsSection ContactUsSection={FinanceData?.ConnectSection} />
            </Suspense>
        </div>
    )
}

export default FinancePage