import React, { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Actions from "../redux/actions"
import { env } from '../config'
import axios from 'axios'
import Loader from '../components/Loader'
const ProductDevelopmentIntroCard = React.lazy(() => import('../components/ProductDevelopmentIntroCard'))
const DevopsandIntegrationProvidersSection = React.lazy(() => import('../components/DevopsandIntegrationProvidersSection'))
const ContactUsSection = React.lazy(() => import('../components/ContactUsSection'))



function DevopsandIntegrationPage() {
    const DevopsandIntegrationData: any = useSelector((state: any) => state?.Page?.DevopsandIntegrationPageContent)

    const dispatch = useDispatch()

    let url = "/api/devops-and-integration-page-content?populate=deep"
    let action = Actions.DevopsandIntegrationpageAction




    useEffect(() => {
        if (Object.keys(DevopsandIntegrationData).length === 0) {
            setTimeout(() => {
                (async () => {
                    await axios.get(`${env}${url}`)
                        .then(function (response) {
                            console.log(response.data.data.attributes);
                            dispatch(action(response?.data?.data?.attributes))

                        })
                        .catch((error: any) => {
                            console.log("error", error);
                        });
                })()
            }, 0);
        }
        window.scrollTo({
            top: 0,
            left: 1,
            behavior: 'smooth'
        });
    }, [dispatch])


    return (
        <div>
            <Suspense fallback={<Loader />}>
                <ProductDevelopmentIntroCard
                    Title1={DevopsandIntegrationData?.Intro?.Title1}
                    Title2={DevopsandIntegrationData?.Intro?.Title2}
                    Description1={DevopsandIntegrationData?.Intro?.Description1}
                    url={DevopsandIntegrationData?.Intro?.Image?.data?.attributes?.url}
                    imgPosition="mx-auto"
                    altText="DevOps &
                Integration"
                    ButtonText={DevopsandIntegrationData?.Intro?.ButtonText}
                />
                <DevopsandIntegrationProvidersSection PartnerImageSection={DevopsandIntegrationData?.PartnerImageSection} IntegrationPartners={DevopsandIntegrationData?.IntegrationPartners} />
                <ContactUsSection ContactUsSection={DevopsandIntegrationData?.Contact} />
            </Suspense>


        </div>
    )
}

export default DevopsandIntegrationPage