import React from 'react'
import HeadingText from './HeadingText'
import { env } from '../config';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router';

function ProductdevelopmentIntro(props: any) {
    const { ProductDevelopmentPageIntro, imagePosition, altText } = props;
    const { ref, inView } = useInView({
        // triggerOnce: true,
        threshold: 0
    });
    let navigate = useNavigate();
    console.log("pProductDevelopmentPageIntro", ProductDevelopmentPageIntro?.Image?.data?.attributes?.url)
    return (
        <div>
            <div className='px-5 py-7 lg:px-10 xl:px-20 lg:py-14 bg-[#FEF2E4] text-black-75 flex-col-reverse flex lg:flex-row lg:justify-between'>
                <div ref={ref} className='lg:w-1/2 flex flex-col justify-center'>
                    <div className={` text-2xl md:text-4xl lg:text-5xl   font-custom font-bold`}>
                        {ProductDevelopmentPageIntro?.Title1}
                    </div>
                    <div className={`text-2xl md:text-4xl lg:text-5xl md:mt-3  font-custom font-bold`}>
                        {inView ? <div dangerouslySetInnerHTML={{ __html: ProductDevelopmentPageIntro?.Title2 }} />
                            : <div className={` text-2xl md:text-4xl lg:text-5xl  font-custom font-bold`}>
                                Development
                            </div>}

                    </div>

                    <div className={`lg:text-lg md:text-base  font-normal mt-3 pt-3`}>
                        {ProductDevelopmentPageIntro?.Description1}

                    </div>
                    <div className={`lg:text-lg md:text-base   font-normal pt-3`}>
                        {ProductDevelopmentPageIntro?.Description2}


                    </div>
                    {ProductDevelopmentPageIntro?.ButtonText ?
                        <button onClick={() => { navigate("/contact-us") }} className=' bg-[#616161] text-white border border-[#616161] hover:bg-white hover:text-[#616161] hover:border hover:border-[#616161] rounded-[50px] w-max px-4 py-2 my-6'>{ProductDevelopmentPageIntro?.ButtonText}</button>
                        : null

                    }

                </div>

                <img alt={altText} loading='lazy' className={`${imagePosition} w-[640px] h-[540px]`} src={`${env}${ProductDevelopmentPageIntro?.Image?.data?.attributes?.url}`} />

            </div>
        </div>
    )
}

export default ProductdevelopmentIntro