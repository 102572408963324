import axios from 'axios';
import React, { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { env } from '../config';
import * as Actions from "../redux/actions"
import Loader from '../components/Loader';
const AgroTechIntro = React.lazy(() => import('../components/AgroTechIntro'))
const AgroTechCardsSection = React.lazy(() => import('../components/AgroTechCardsSection'))
const AgroTechConnectSection = React.lazy(() => import('../components/AgroTechConnectSection'))



function AgroTechPage() {
    const dispatch = useDispatch()
    const AgrotechData: any = useSelector((state: any) => state?.Page?.AgrotechPageContent)








    const getAgrotechPageContent = () => {
        axios.get(`${env}/api/agrotech-page-content?populate=deep`)
            .then(function (response) {
                console.log("njjj", response?.data?.data?.attributes);
                dispatch(Actions.AgrotechPageAction(response?.data?.data?.attributes))

            })
    }


    useEffect(() => {
        if (Object.keys(AgrotechData).length === 0) {
            getAgrotechPageContent();
            console.log("inside useeffect =>", AgrotechData)


        }
        window.scrollTo({
            top: 0,
            left: 1,
            behavior: 'smooth'
        });

    }, [])
    console.log("Outside useeffect =>", AgrotechData?.AgrotechConnectSection)

    return (
        <div>
            <Suspense fallback={<Loader />}>
                <AgroTechIntro AgrotechIntro={AgrotechData?.AgrotechIntro} />

                {AgrotechData?.AgrotechCards ?
                    <AgroTechCardsSection AgrotechCards={AgrotechData?.AgrotechCards} />
                    : null

                }
                <AgroTechConnectSection AgrotechConnectSection={AgrotechData?.AgrotechConnectSection} />
            </Suspense>
        </div>
    )
}

export default AgroTechPage

