import React from 'react'
import { env } from '../config';
import HeadingText from './HeadingText'
import Voifinitycarrds from './Voifinitycarrds';
import { useNavigate } from 'react-router';

function VoinfinityTelcom(props: any) {
    const { TelecomVoifinitySection } = props;
    console.log("TelecomVoifinitySection", TelecomVoifinitySection)
    // const openInNewTab = (url: any) => {
    //     window.open(url, '_blank', 'noreferrer');
    // };
    const navigate = useNavigate()
    // console.log("TelecomVoifinitySection?.TelecomVoifinityFeaturesTitle?.Title", TelecomVoifinitySection?.TelecomVoifinityFeaturesTitle?.Title)

    // bg-red-600 sm:bg-yellow-600 md:bg-green-700 lg:bg-teal-600 xl:bg-purple-800
    return (
        <div >
            <div id="ncs" className=' md:h-auto px-5 py-7 lg:px-10 xl:px-20 lg:py-14 xl:py-24 text-black-75 relative  flex flex-col justify-start lg:flex-row md:justify-center items-center'>
                <img data-aos="fade-up" className=' z-20 lg:w-3/4 h-auto' src={`${env}${TelecomVoifinitySection?.Image?.data?.attributes?.url}`} alt={TelecomVoifinitySection?.TitleContent?.Title} />
                <div data-aos="slide-left" className=' md:w-full  my-auto'>
                    <HeadingText padding="p-5 " position="flex sm:justify-start justify-center p-5 pb-0" logo={TelecomVoifinitySection?.logo} Heading={TelecomVoifinitySection?.product} Body={TelecomVoifinitySection?.description} />
                    <button onClick={() => navigate(`${TelecomVoifinitySection?.link}`)} className='mx-auto sm:mx-0 sm:ml-5 rounded-[30px] flex items-center  bg-[#616161] text-white border border-[#616161] hover:bg-white hover:text-[#616161] hover:border hover:border-[#616161] px-4 py-2'>{TelecomVoifinitySection?.buttonText}</button>
                </div>

                <svg className='absolute pointer-events-none z-10 left-[20%]' width="324" height="303" viewBox="0 0 324 303" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.8466 59.0533C-7.06273 31.098 15.1315 -0.348751 45.7961 2.44908L285.394 24.3099C316.059 27.1078 332.196 62.0519 314.44 87.2093L175.709 283.777C157.954 308.934 119.623 305.437 106.714 277.482L5.8466 59.0533Z" stroke="#5F5F5F" stroke-opacity="0.2" stroke-width="4" />
                </svg>
                <svg className='absolute pointer-events-none right-0 top-[40%]' width="123" height="203" viewBox="0 0 123 203" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M159.185 3.36151C186.187 -2.87646 209.816 22.4549 201.717 48.958L163.113 175.294C155.014 201.797 121.262 209.595 102.359 189.329L12.251 92.7289C-6.65209 72.4638 3.47109 39.335 30.4727 33.097L159.185 3.36151Z" stroke="#5F5F5F" stroke-opacity="0.2" stroke-width="4" />
                </svg>
            </div>
            <Voifinitycarrds Features={TelecomVoifinitySection?.ProductCard} title={TelecomVoifinitySection?.subtitle} />


        </div>
    )
}

export default VoinfinityTelcom