import React, { Suspense } from 'react'
import { useEffect } from 'react'
import HomeIntro from '../components/HomeIntro'
import { useSelector } from 'react-redux'
import Loader from '../components/Loader'
const InnovativeSlider = React.lazy(() => import('../components/InnovativeSliderHome'));
const ServicesHome = React.lazy(() => import("../components/ServicesHome"));
const HomePageExpertiseDomainSection = React.lazy(() => import('../components/HomePageExpertiseDomainSection'))
const Brouchure = React.lazy(() => import('../components/BrouchureHome'))
const History = React.lazy(() => import('../components/HistoryHome'))
const AgroTechConnectSection = React.lazy(() => import('../components/AgroTechConnectSection'))
const Countries = React.lazy(() => import('../components/CountriesHome'))


function Home() {





    const HomeData: any = useSelector((state: any) => state?.Page?.HomePageContent)







    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 1,
            behavior: 'smooth'
        });

    }, [])




    return (
        <Suspense fallback={<Loader />}>
            <HomeIntro HomepageTitleContent={HomeData?.HomepageTitleContent} />
            <InnovativeSlider HomeWhoWeAre={HomeData?.HomeWhoWeAre} imagesSection={HomeData?.imagesSection} />
            <ServicesHome HomeServices={HomeData?.HomeServices} />
            <HomePageExpertiseDomainSection ExpertiseDomain={HomeData?.ExpertiseDomain} />
            <Brouchure HomeBrochure={HomeData?.HomeBrochure} />
            <History HomeHistorySection={HomeData?.HomeHistorySection} />
            <Countries HomeCountries={HomeData?.HomeCountries} />
            <AgroTechConnectSection AgrotechConnectSection={HomeData?.Contactus} widthProp="lg:w-[50%]" />
        </Suspense>
    )

}





export default Home