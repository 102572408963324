import React, { Suspense, useEffect } from 'react'
import CareerIndividualInputFields from '../components/CareerIndividualInputFields'
import CareerIndividualJobDescription from '../components/CareerIndividualJobDescription'
import CareerIndividualJobDescriptionCard from '../components/CareerIndividualJobDescriptionCard'
import { useDispatch, useSelector } from 'react-redux';
import { env } from '../config'
import axios from 'axios'
import * as Actions from "../redux/actions"
import { useLocation } from 'react-router'
const CareerIndividualIntro = React.lazy(() => import('../components/CareerIndividualIntro'))



function CareerIndividualPage() {
    const location = useLocation();
    console.log("location", location?.state?.id?.id)
    const dispatch = useDispatch()
    const JobList: any = useSelector((state: any) => state?.Page?.CareerIndividualarray)
    console.log("Joblist", JobList);



    const getJobListData = () => {
        axios.get(`${env}/api/job-lists?populate=deep`)
            .then(function (response) {
                console.log("CareerIndividualAction", response?.data?.data);
                dispatch(Actions.CareerIndividualAction(response?.data?.data))

            })
    }






    useEffect(() => {

        console.log("inside useeffect")
        window.scrollTo({
            top: 0,
            left: 1,
            behavior: 'smooth'
        });
        if (Object.keys(JobList)?.length === 0) {
            getJobListData();
        }



    }, [])




    return (
        <div>
            <Suspense fallback={<></>}>
                <CareerIndividualIntro startId={location?.state?.id?.id} JobList={JobList} />
            </Suspense>
        </div>
    )
}

export default CareerIndividualPage