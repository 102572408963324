import React from 'react'
import { env } from '../config';

function HrmnestCards(props: any) {
    const { title, Features, } = props;
    console.log("fetaures",)
    return (
        <div>
            <div className={`text-black-75  px-5 py-7 lg:px-10 xl:px-20 lg:py-14  `}>
                <div className={`text-2xl md:text-4xl lg:text-5xl flex justify-center mb-11 mx-auto lg:w-[40%] leading-loose text-center font-custom font-bold`}>
                    {title}
                </div>

                <div className=' grid  md:grid-cols-2 gap-3 '>

                    {Features?.map((node: any, index: number) => {
                        console.log("first", node)
                        return (
                            <div data-aos="fade-left" data-aos-delay={`${index === 0 ? 150 : index * 150}`} className={`  p-6 rounded-[10px] bg-white  md:h-[300px] lg:h-auto flex flex-col  md:text-left md:items-start justify-evenly`}>
                                {node?.icon?.data?.attributes?.url &&
                                    <img className='w-max' src={`${env}${node?.icon?.data?.attributes?.url}`} alt="not available" />
                                }

                                <div className='font-bold font-custom  md:text-base text-base py-3'>
                                    {node?.title}
                                </div>
                                <div className='font-custom md:text-base text-base  '>
                                    {node?.description}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default HrmnestCards