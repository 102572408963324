import React, { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Actions from "../redux/actions"
import axios from 'axios'
import { env } from '../config'
import Loader from '../components/Loader'
const NsepIntro = React.lazy(() => import('../components/NsepIntro'))
const NsepHighlightSection = React.lazy(() => import('../components/NsepHighlightSection'))
const NsepFAQ = React.lazy(() => import('../components/NsepFAQ'))


function NsepPage() {
    const NsepData: any = useSelector((state: any) => state?.Page?.NsepPageContent)
    console.log("nsep", NsepData?.NsepFAQSection);




    const dispatch = useDispatch()

    let url = "/api/nsep-page-content?populate=deep"
    let action = Actions.NsepPageAction



    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 1,
            behavior: 'smooth'
        });
        if (Object.keys(NsepData).length === 0) {
            setTimeout(() => {
                (async () => {
                    await axios.get(`${env}${url}`)
                        .then(function (response) {
                            console.log(response.data.data.attributes);
                            dispatch(action(response?.data?.data?.attributes))

                        })
                        .catch((error: any) => {
                            console.log("error", error);
                        });
                })()
            }, 0);
        }







    }, [dispatch])





    return (
        <Suspense fallback={<Loader />}>
            <div>
                <NsepIntro NsepIntroContent={NsepData?.NsepIntroContent} />
                <NsepHighlightSection NsepHighlightSection={NsepData?.NsepHighlightSection} />
                <NsepFAQ NsepFAQSection={NsepData?.NsepFAQSection} />
            </div>

        </Suspense>
    )
}

export default NsepPage