import React from 'react'
import { useInView } from 'react-intersection-observer';
import { env } from '../config';
import { useNavigate } from 'react-router';

function ProductDevelopmentIntroCard(props: any) {
    const { Title1, Title2, Description1, Description2, ButtonText, url, position = "flex-col-reverse flex lg:flex-row lg:justify-between", imgPosition, altText } = props;
    // flex-col-reverse flex lg:flex-row lg:justify-between
    const { ref, inView } = useInView({
        // triggerOnce: true,
        threshold: 0
    });
    console.log("alttext", altText)
    const navigate = useNavigate()


    return (
        <div>
            <div className={`px-5 py-7 lg:px-10 xl:px-20 lg:py-14  bg-[#FEF2E4] text-black-75 ${position}`} >
                <div ref={ref} className='lg:w-1/2 flex flex-col justify-center'>
                    <div className={`text-3xl md:text-4xl lg:text-5xl    font-custom font-bold`}>
                        <div dangerouslySetInnerHTML={{ __html: Title1 }} />

                    </div>
                    <div className={`text-3xl md:text-4xl lg:text-5xl md:mt-3  font-custom font-bold`}>
                        {inView ? <div dangerouslySetInnerHTML={{ __html: Title2 }} />
                            : <div className={` text-2xl md:text-4xl lg:text-5xl  font-custom font-bold`}>
                                Development
                            </div>}

                    </div>

                    <div className={`lg:text-lg md:text-base mt-3  font-normal pt-3`}>
                        {Description1}

                    </div>
                    <div className={`lg:text-lg md:text-base   font-normal pt-3`}>
                        {Description2}


                    </div>
                    {ButtonText ?
                        <button onClick={() => { Title1.toLowerCase().includes("ncs") ? navigate("/ncs/contact") : Title1.toLowerCase().includes("netstratum") ? navigate("/ncs/contact") : navigate("/contact-us") }} className='bg-[#616161] text-white rounded-[50px] w-max px-3 py-2 my-6'>{ButtonText}</button>
                        : null
                    }

                </div>
                {url ?
                    <img alt={altText} className={`${imgPosition}`} src={`${env}${url}`} />
                    : null
                }

            </div>


        </div>
    )
}

export default ProductDevelopmentIntroCard