import React, { Suspense, useEffect, useState } from 'react'
import ContactIntro from '../components/ContactIntro'
import axios from 'axios'
import * as Actions from "../redux/actions"
import { env } from '../config'
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/Loader'
const GalleryPagesIntro = React.lazy(() => import('../components/GalleryPagesIntro'))
const EthnicDayGrid = React.lazy(() => import('../components/EthnicDayGrid'))



function EthnicDayPage() {
    const dispatch = useDispatch()
    const EthnicDayData: any = useSelector((state: any) => state?.Page?.EthnicDayPageContent)


    const [isLoading, setisLoading] = useState(true)

    const handleLoading = () => {
        setTimeout(() => {
            setisLoading(false);

        }, 1000);

    }


    useEffect(() => {
        window.addEventListener("load", handleLoading);
        return () => window.removeEventListener("load", handleLoading);
    }, [])


    const getEthnicPageData = () => {
        axios.get(`${env}/api/ethnic-day-page-content?populate=deep`)
            .then(function (response) {
                console.log(response.data.data.attributes);
                dispatch(Actions.EthnicDaypageAction(response?.data?.data?.attributes))

            })
    }

    useEffect(() => {
        if (Object.keys(EthnicDayData).length === 0) {
            getEthnicPageData();
        }

    }, [dispatch])


    return !isLoading ? (


        <>
            <Suspense fallback={<></>}>

                < GalleryPagesIntro ContactPageIntro={EthnicDayData?.EthnicDayPageIntroImage} />
            </Suspense>
            <Suspense fallback={<></>}>

                <EthnicDayGrid Gallery={EthnicDayData?.EthnicDayPageIntroImage?.Gallery} />
            </Suspense>

        </>)
        :
        (<Loader />)









}

export default EthnicDayPage