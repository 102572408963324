import React from 'react'
import HomeServicesCard from './HomeServicesCard';
import Iconmap from './Iconmap';
import { useLocation, useNavigate } from 'react-router';
import { env } from '../config';

function ProductDevelopmentFeaturesSection(props: any) {
    let navigate = useNavigate()
    const { Features, title } = props;
    console.log("Features", Features)

    const location = useLocation()

    const checkRouteandNavigate = (route: string) => {
        console.log("route", route)
        if (route === "E-commerce and Enterprise Portal") {
            navigate("/product-development/ecommerce-development")
        }
        if (route === "Application Development") {
            navigate("/product-development/application-development")
        }
        if (route === "Quality Assurance & Testing") {
            navigate("/product-development/qualityassurance&testing")
        }
        if (route === "Telecom Systems and Integrations") {
            navigate("/product-development/telecom-system")
        }
        if (route === "Cloud Services") {
            navigate("/product-development/cloud-services")
        }
        if (route === "IT Consulting & Strategy") {
            navigate("/product-development/it-consulting")
        }








    }
    return (
        <div className='text-black-75 bg-[#FEF2E4] '>
            <div className={`text-2xl md:text-4xl lg:text-5xl flex justify-center   font-custom font-bold`}>
                {title}
            </div>

            <div className='px-5 py-7 lg:px-10 xl:px-20 lg:py-14 grid  md:grid-cols-2 lg:grid-cols-3 gap-3 '>

                {Features?.map((node: any, index: number) => {
                    return (
                        <div key={index + node?.Title} data-aos="fade-left" data-aos-delay={`${index === 0 ? 150 : index * 150}`} className={`p-4 rounded-[10px] bg-white   flex flex-col  md:text-left md:items-start justify-evenly`}>
                            {node?.Image?.data ?
                                <img className='w-12 h-12' src={`${env}${node?.Image?.data?.attributes?.url}`} alt="not available" />


                                : <Iconmap type={node?.IconType} />

                            }

                            <div className='font-bold font-custom  md:text-base text-base  py-3'>
                                {node?.Title}
                            </div>
                            <div className='font-custom md:text-base text-base '>
                                {node?.Description}
                            </div>
                            {!location?.pathname?.toLowerCase().includes("projectcommunitycommerce") &&
                                <div className='my-3'>
                                    <button onClick={() => { checkRouteandNavigate(node?.Title) }} className='border-[#808285] border text-[#808285] hover:bg-[#8A8A8A] hover:text-white md:text-base   rounded-[50px] px-4 py-2 font-custom'>View More</button>
                                </div>
                            }

                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ProductDevelopmentFeaturesSection