import axios from 'axios'
import React, { Suspense, useEffect } from 'react'
import * as Actions from "../redux/actions"
import { useDispatch, useSelector } from 'react-redux'
import { env } from '../config'
import Loader from '../components/Loader'
import ContactUsSection from '../components/ContactUsSection'
const DataAnalyticsIntro = React.lazy(() => import('../components/ProductdevelopmentIntro'))
const DataAnalyticspartners = React.lazy(() => import("../components/ItConsultingHomePartnersSection"))
const DataAnalyticsCards = React.lazy(() => import("../components/DataAnalyticsCards"))
const DataAnalyticsOfferSection = React.lazy(() => import("../components/DataAnalyticsWhatWeOffer"))


function DataAnalyticsPage() {
    const DataAnalyticsData: any = useSelector((state: any) => state?.Page?.DataAnalyticsPageContent)
    const dispatch = useDispatch()

    let url = "/api/data-analytics-page-content?populate=deep"
    let action = Actions.DataAnalyticspageAction

    console.log("gggg", DataAnalyticsData?.DataAnalyticsPageContent)



    useEffect(() => {
        if (Object.keys(DataAnalyticsData).length === 0) {
            setTimeout(() => {
                (async () => {
                    await axios.get(`${env}${url}`)
                        .then(function (response) {
                            console.log(response.data.data.attributes);
                            dispatch(action(response?.data?.data?.attributes))

                        })
                        .catch((error: any) => {
                            console.log("error", error);
                        });
                })()
            }, 0);
        }
        window.scrollTo({
            top: 0,
            left: 1,
            behavior: 'smooth'
        });
    }, [dispatch])



    return (
        <>
            <Suspense fallback={<Loader />}>
                <DataAnalyticsIntro imagePosition="mx-auto" ProductDevelopmentPageIntro={DataAnalyticsData?.DataAnalyticsPageContent} />
                <DataAnalyticspartners IoTPartners={DataAnalyticsData?.Partners} />
                <DataAnalyticsCards title={DataAnalyticsData?.CardsSection?.Title} Features={DataAnalyticsData?.CardsSection?.Cards} />
                <DataAnalyticsOfferSection IoTUseCasesCards={DataAnalyticsData?.WhatweOffer} />
                <ContactUsSection ContactUsSection={DataAnalyticsData?.ContactUs} />

            </Suspense>
        </>
    )
}

export default DataAnalyticsPage