 export const HOMEPAGE="HOMEPAGE";
 export const EXPERTISEPAGE="EXPERTISEPAGE";
 export const TELECOMPAGE="TELECOMPAGE";
 export const IOTPAGE="IOTPAGE";
 export const IOTPLATFORMPAGE="IOTPLATFORMPAGE";
 export const SERVICESPAGE="SERVICESPAGE";
 export const BLOGPAGE="BLOGPAGE";
 export const CAREERPAGE="CAREERPAGE";
 export const SMARTCITYPAGE="SMARTCITYPAGE";
 export const CAREERINDIVIDUAL="CAREERINDIVIDUAL";
 export const JOBSELECTION="JOBSELECTION";
 export const NSEPPAGE="NSEPPAGE";
 export const CONTACTPAGE="CONTACTPAGE";
 export const AGROTECHPAGE="AGROTECHPAGE";
 export const OFFICELIST="OFFICELIST";
 export const BLOGCONTENT="BLOGCONTENT";
 export const IOTDEVELOPMENTPAGE="IOTDEVELOPMENTPAGE";
 export const FARMTOPLATEPAGE="FARMTOPLATEPAGE";
 export const BARGEPAGE="BARGEPAGE";
 export const PRODUCTDEVELOPMENTPAGE="PRODUCTDEVELOPMENTPAGE";
 export const APPLICATIONDEVELOPMENTPAGE="APPLICATIONDEVELOPMENTPAGE";
 export const ECOMMERCEDEVELOPMENTPAGE="ECOMMERCEDEVELOPMENTPAGE";
 export const CLOUDSERVICESPAGE="CLOUDSERVICESPAGE";
 export const QUALITYASSURANCEPAGE="QUALITYASSURANCEPAGE";
 export const TELECOMSYSTEMSPAGE="TELECOMSYSTEMSPAGE";
 export const ITCONSULTINGPAGE="ITCONSULTINGPAGE";
 export const DIGITALTRANSFORMATIONPAGE="DIGITALTRANSFORMATIONPAGE";
 export const ITCONSULTINGHOMEPAGE="ITCONSULTINGHOMEPAGE";
 export const CLOUDSOLUTIONSPAGE="CLOUDSOLUTIONSPAGE";
 export const DEVOPSANDINTEGRATIONPAGE="DEVOPSANDINTEGRATIONPAGE";
 export const ETHNICDAYPAGE="ETHNICDAYPAGE";
 export const COMPANYPAGE="COMPANYPAGE";
 export const FINANCEPAGE="FINANCEPAGE";
 export const DATAANALYTICSPAGE="DATAANALYTICSPAGE";
 export const PRODUCTDATA="PRODUCTDATA";
 export const WEBSITEDEVELOPMENTLANDINGPAGE="WEBSITEDEVELOPMENTLANDINGPAGE";
 export const MOBILEAPPDEVELOPMENTLANDINGPAGE="MOBILEAPPDEVELOPMENTLANDINGPAGE";
 export const WIKIPEEDIKAPAGE="WIKIPEEDIKAPAGE";
 export const WIKIPEEDIKABLOGCONTENT="WIKIPEEDIKABLOGCONTENT";
 export const NCSPAGE="NCSPAGE";
 export const NCSINDIVIDUALPAGES="NCSINDIVIDUALPAGES";
 export const COMMNUNITYCOMMERCEPAGE="COMMNUNITYCOMMERCEPAGE";
 export const OFFSHOREWINDPAGE="OFFSHOREWINDPAGE";






































 


 
 export const HomePageAction=(data:any)=>{
    return{
        type:HOMEPAGE,
        payload:data
    }
 }

 export const ExpertisePageAction=(data:any)=>{
    return{
        type:EXPERTISEPAGE,
        payload:data
    }
 }

 export const TelecomPageAction=(data:any)=>{
    return{
        type:TELECOMPAGE,
        payload:data
    }
 }
 export const IotPageAction=(data:any)=>{
    return{
        type:IOTPAGE,
        payload:data
    }
 }
 export const IotPlatformPageAction=(data:any)=>{
    return{
        type:IOTPLATFORMPAGE,
        payload:data
    }
 }
 export const ServicesPageAction=(data:any)=>{
    return{
        type:SERVICESPAGE,
        payload:data
    }
 }
 
 export const BlogPageAction=(data:any)=>{
    return{
        type:BLOGPAGE,
        payload:data
    }
 }
 export const CareerPageAction=(data:any)=>{
    return{
        type:CAREERPAGE,
        payload:data
    }
 }
 export const SmartCityPageAction=(data:any)=>{
    return{
        type:SMARTCITYPAGE,
        payload:data
    }
 }
 export const CareerIndividualAction=(data:any)=>{
    return{
        type:CAREERINDIVIDUAL,
        payload:data
    }
 }
 export const JobSelection=(data:any)=>{
    return{
        type:JOBSELECTION,
        payload:data
    }
 }
 export const NsepPageAction=(data:any)=>{
    return{
        type:NSEPPAGE,
        payload:data
    }
 }
 export const OfficeListAction=(data:any)=>{
    return{
        type:OFFICELIST,
        payload:data
    }
 }
 export const ContactPageAction=(data:any)=>{
    return{
        type:CONTACTPAGE,
        payload:data
    }
 }
 export const AgrotechPageAction=(data:any)=>{
    return{
        type:AGROTECHPAGE,
        payload:data
    }
 }
 export const BlogContentAction=(data:any)=>{
    return{
        type:BLOGCONTENT,
        payload:data
    }
 }
 export const IotDevelopmentPageAction=(data:any)=>{
    return{
        type:IOTDEVELOPMENTPAGE,
        payload:data
    }
 }
 export const FarmToPlatePageAction=(data:any)=>{
    return{
        type:FARMTOPLATEPAGE,
        payload:data
    }
 }
 export const BargePageAction=(data:any)=>{
    return{
        type:BARGEPAGE,
        payload:data
    }
    
 }
 export const ProductDevelopmentpageAction=(data:any)=>{
    return{
        type:PRODUCTDEVELOPMENTPAGE,
        payload:data
    }
    
 }
  export const ApplicationDevelopmentpageAction=(data:any)=>{
    return{
        type:APPLICATIONDEVELOPMENTPAGE,
        payload:data
    }
    
 }
 export const EcommerceDevelopmentpageAction=(data:any)=>{
    return{
        type:ECOMMERCEDEVELOPMENTPAGE,
        payload:data
    }
    
 }
 export const CloudServicespageAction=(data:any)=>{
    return{
        type:CLOUDSERVICESPAGE,
        payload:data
    }
    
 }
 export const QualityAssurancepageAction=(data:any)=>{
    return{
        type:QUALITYASSURANCEPAGE,
        payload:data
    }
    
 }
 export const TelecomSystemspageAction=(data:any)=>{
    return{
        type:TELECOMSYSTEMSPAGE,
        payload:data
    }
    
 }
 export const ItConsultingpageAction=(data:any)=>{
    return{
        type:ITCONSULTINGPAGE,
        payload:data
    }
    
 }
 export const DigitalTransformationpageAction=(data:any)=>{
    return{
        type:DIGITALTRANSFORMATIONPAGE,
        payload:data
    }
}
export const ItConsultingHomepageAction=(data:any)=>{
    return{
        type:ITCONSULTINGHOMEPAGE,
        payload:data
    }
}
export const CloudSolutionspageAction=(data:any)=>{
    return{
        type:CLOUDSOLUTIONSPAGE,
        payload:data
    }
    

}
export const DevopsandIntegrationpageAction=(data:any)=>{
    return{
        type:DEVOPSANDINTEGRATIONPAGE,
        payload:data
    }
    

}
export const EthnicDaypageAction=(data:any)=>{
    return{
        type:ETHNICDAYPAGE,
        payload:data
    }
    

}
export const CompanypageAction=(data:any)=>{
    return{
        type:COMPANYPAGE,
        payload:data
    }
    

}
export const FinancepageAction=(data:any)=>{
    return{
        type:FINANCEPAGE,
        payload:data
    }
    

}
export const DataAnalyticspageAction=(data:any)=>{
    return{
        type:DATAANALYTICSPAGE,
        payload:data
    }
    

}

export const ProductDataAction=(data:any)=>{
    return{
        type:PRODUCTDATA,
        payload:data
    }
    

}
export const WebsiteDevelopmentLandingpageAction=(data:any)=>{
    return{
        type:WEBSITEDEVELOPMENTLANDINGPAGE,
        payload:data
    }
    

}
export const MobileAppDevelopmentLandingpageAction=(data:any)=>{
    return{
        type:MOBILEAPPDEVELOPMENTLANDINGPAGE,
        payload:data
    }
    

}
export const WikipeedikapageAction=(data:any)=>{
    return{
        type:WIKIPEEDIKAPAGE,
        payload:data
    }
    

}
export const WikipeedikaBlogContentAction=(data:any)=>{
    return{
        type:WIKIPEEDIKABLOGCONTENT,
        payload:data
    }
    

}

export const NCSpageAction=(data:any)=>{
    return{
        type:NCSPAGE,
        payload:data
    }
}

export const NCSIndividualpageAction=(data:any)=>{
    return{
        type:NCSINDIVIDUALPAGES,
        payload:data
    }
}

export const CommunityCommercePageAction=(data:any)=>{
    return{
        type:COMMNUNITYCOMMERCEPAGE,
        payload:data
    }
}

export const OfsshoreWindPageAction=(data:any)=>{
    return{
        type:OFFSHOREWINDPAGE,
        payload:data
    }
}





 