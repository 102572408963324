import React, { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { env } from '../config'
import * as Actions from "../redux/actions"
import Loader from '../components/Loader'
const ProductdevelopmentIntro = React.lazy(() => import('../components/ProductdevelopmentIntro'))
const ProductDevelopmentFeaturesSection = React.lazy(() => import('../components/ProductDevelopmentFeaturesSection'))
const ProductDevelopmentProductsArray = React.lazy(() => import('../components/ProductDevelopmentProductsArray'))
const WhychooseNetratumProduct = React.lazy(() => import('../components/WhychooseNetratumProduct'))
const ContactUsSection = React.lazy(() => import('../components/ContactUsSection'))



function ProductDevelopmentPage() {
    const ProductDevelopmentData: any = useSelector((state: any) => state?.Page?.ProductDevelopmentPageContent)
    const dispatch = useDispatch()



    let url = "/api/product-development-page-content?populate=deep"
    let action = Actions.ProductDevelopmentpageAction



    useEffect(() => {
        if (Object.keys(ProductDevelopmentData).length === 0) {
            setTimeout(() => {
                (async () => {
                    await axios.get(`${env}${url}`)
                        .then(function (response) {
                            console.log(response.data.data.attributes);
                            dispatch(action(response?.data?.data?.attributes))

                        })
                        .catch((error: any) => {
                            console.log("error", error);
                        });
                })()
            }, 0);
        }
        window.scrollTo({
            top: 0,
            left: 1,
            behavior: 'smooth'
        });
    }, [dispatch])





    return (
        <Suspense fallback={<Loader />}>
            <ProductdevelopmentIntro altText="Product Development" ProductDevelopmentPageIntro={ProductDevelopmentData?.ProductDevelopmentPageIntro} />
            {ProductDevelopmentData?.Features?.CardsSection ?
                <ProductDevelopmentFeaturesSection title={ProductDevelopmentData?.Features?.Title} Features={ProductDevelopmentData?.Features?.CardsSection} />
                : null}
            <ProductDevelopmentProductsArray ProductArraySection={ProductDevelopmentData?.ProductArraySection} />
            <WhychooseNetratumProduct WhyChooseSection={ProductDevelopmentData?.WhyChooseSection} />
            <ContactUsSection ContactUsSection={ProductDevelopmentData?.ConnectSection} />
        </Suspense>

    )
}

export default ProductDevelopmentPage