import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../redux/actions'
import { env } from '../config';
import axios from 'axios';
import Loader from '../components/Loader';
import ProductdevelopmentIntro from '../components/ProductdevelopmentIntro';
import ProductDevelopmentFeaturesSection from '../components/ProductDevelopmentFeaturesSection';
import ContactUsSection from '../components/ContactUsSection';

const PeojectCommunityCommerce = () => {
    // CommunityCommercePageContent: {}
    // export const CommunityCommercePageAction=(data:any)=>{
    //     return{
    //         type:COMMNUNITYCOMMERCEPAGE,
    //         payload:data
    //     }
    const CommunityData: any = useSelector((state: any) => state?.Page?.CommunityCommercePageContent)
    console.log("HOOMMRR", CommunityData);
    const dispatch = useDispatch()


    const url = "/api/community-commerce-page?populate=deep"


    useEffect(() => {
        if (Object.keys(CommunityData)?.length === 0) {
            setTimeout(() => {
                (async () => {
                    await axios
                        .get(`${env}${url}`)
                        .then(async (response: any) => {

                            dispatch(Actions.CommunityCommercePageAction(response?.data?.data?.attributes))

                        })
                        .catch((error: any) => {
                            console.log("error", error);
                        });
                })()

            }, 0);


        }


        window.scrollTo({
            top: 0,
            left: 1,
        });



    }, [dispatch])


    return (
        CommunityData ?
            <>
                <ProductdevelopmentIntro altText="Product Development" ProductDevelopmentPageIntro={CommunityData?.Intro} />
                <ProductDevelopmentFeaturesSection title={CommunityData?.Features?.Title} Features={CommunityData?.Features?.CardsSection} />
                <ContactUsSection ContactUsSection={CommunityData?.ConnectSection} />

            </>
            :
            <Loader />
    )
}

export default PeojectCommunityCommerce