import axios from 'axios';
import { useState } from 'react'
import { env } from '../config';

function HomeIntro(props: any) {
    const [video, setvideo] = useState(false)
    const [videoUrl, setvideoUrl] = useState("")



    const changeOverlay = () => {
        axios.get(`${env}/api/banner-videos?populate=deep`)
            .then(function (response) {
                console.log("response.data.data", response?.data?.data[0]?.attributes?.BannerVideo?.data?.attributes.url);
                setvideoUrl(response?.data?.data[0]?.attributes?.BannerVideo?.data?.attributes.url)
                setvideo(!video)
            })

    }



    const { HomepageTitleContent } = props;
    console.log("urlll", HomepageTitleContent?.Image)

    return (<>
        <div className='relative'>

            <img onMouseEnter={() => changeOverlay()} className={`${video === false ? "opacity-100" : "opacity-0 duration-1000"} w-screen h-auto`} src={`https://imagedelivery.net/pN4U1Bz5oPlEGJO_1sM7lw/0471ac60-bc08-43b3-27ca-dab75d0f1400/high`} alt="Hero" />

            {videoUrl?.length > 0 ?
                <video className={`${video ? "block opacity-100 duration-1000" : "hidden duration-1000 opacity-0 "} w-screen h-auto absolute inset-0`} src={`${env}${videoUrl}`} autoPlay loop muted />
                :
                <iframe className={`${video ? "block opacity-100 duration-1000" : "hidden duration-1000 opacity-0 "} w-[300%] h-[100%] ml-[-100%] absolute  inset-0`} src={`${video ? "https://www.youtube.com/embed/-E2VJeN8z2o?playlist=-E2VJeN8z2o&loop=1&controls=0&autoplay=1&mute=1&loop=1&enablejsapi=1" : ""}`} title="Hero Video" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            }






        </div>
    </>

    )
}

export default HomeIntro


