import axios from 'axios'
import React, { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { env } from '../config'
import * as Actions from "../redux/actions"
import Loader from '../components/Loader'
const FarmToPlateIntro = React.lazy(() => import('../components/FarmToPlateIntro'))
const FarmToPlateCardsSection = React.lazy(() => import('../components/FarmToPlateCardsSection'))
const ContactUsSection = React.lazy(() => import('../components/ContactUsSection'))




function FarmToPlatePage() {
    const dispatch = useDispatch()
    const FarmToPlateData: any = useSelector((state: any) => state?.Page?.FarmToPlatePageContent)









    const getFarmToPlateData = () => {
        axios.get(`${env}/api/farm-to-plate-page-content?populate=deep`)
            .then(function (response) {
                console.log("kkklajsdkj", response?.data?.data?.attributes);
                dispatch(Actions.FarmToPlatePageAction(response?.data?.data?.attributes))

            })
    }



    useEffect(() => {
        if (Object.keys(FarmToPlateData).length === 0) {
            console.log("kkklajsdkj");

            getFarmToPlateData();
        }
        window.scrollTo({
            top: 0,
            left: 1,
            behavior: 'smooth'
        });

        console.log("FarmToPlateDatainsideuseeffect", FarmToPlateData?.FarmToPlateCard)


    }, [dispatch])

    console.log("FarmToPlateData", FarmToPlateData)


    return (
        <div>
            <Suspense fallback={<Loader />}>
                <FarmToPlateIntro FarmToPlateIntro={FarmToPlateData?.FarmToPlateIntro} />


                {FarmToPlateData?.FarmToPlateCard ?
                    <FarmToPlateCardsSection FarmToPlateCard={FarmToPlateData?.FarmToPlateCard} />
                    : null

                }
                <ContactUsSection ContactUsSection={FarmToPlateData?.ContactUsSection} />
            </Suspense>
        </div>
    )
}

export default FarmToPlatePage


