import React from 'react'
import HeadingText from './HeadingText'
import { useLocation, useNavigate } from 'react-router';
import { NavHashLink } from 'react-router-hash-link';

function ContactUsSection(props: any) {
    let navigate = useNavigate()
    const { ContactUsSection } = props;

    const scrollWithOffset = (el: any) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -200;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }

    const location = useLocation()

    return (
        <div>
            <div className={`bg-[#393939] px-5 py-7 lg:px-20 lg:py-14`}>
                <div data-aos="zoom-out" className={`text-2xl md:text-4xl lg:text-5xl flex justify-center text-white text-center  font-custom font-bold`}>
                    {ContactUsSection?.Title}
                </div>
                <div data-aos="fade-down" className={`lg:text-lg md:text-base text-sm flex justify-center lg:w-[60%] 2xl:w-[50%] lg:mx-auto text-white text-center font-normal py-5`}>
                    {ContactUsSection?.Description}

                </div>
                <div data-aos="flip-up" data-aos-easing="linear"
                    data-aos-duration="500" className='flex justify-center'>
                    <NavHashLink scroll={el => scrollWithOffset(el)} smooth to={`${location?.pathname?.includes("ncs") ? `/ncs/contact` : "/contact-us#getintouch"}`}> <button className=' bg-[#616161] text-white border border-[#616161] hover:bg-white hover:text-[#616161] hover:border hover:border-[#616161] rounded-[50px] w-max px-4 py-2 my-3'>
                        {ContactUsSection?.ButtonText}
                    </button>
                    </NavHashLink>
                </div>
            </div>


        </div>
    )
}

export default ContactUsSection