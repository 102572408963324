import React, { useEffect } from 'react'
import * as Actions from '../redux/actions'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { env } from '../config'
import BargePageIntro from '../components/BargePageIntro'
import BargeCardsSection from '../components/BargeCardsSection'
import ContactGetInTouchSection from '../components/ContactGetInTouchSection'
import ContactUsSection from '../components/ContactUsSection'

const OffshoreWindpage = () => {

    const url = "/api/offshore-windpage?populate=deep"

    const OffshoreWind: any = useSelector((state: any) => state?.Page?.OffShoreWindPageContent)
    console.log("HOOMMRR", OffshoreWind);
    const dispatch = useDispatch()




    useEffect(() => {
        if (Object.keys(OffshoreWind)?.length === 0) {
            setTimeout(() => {
                (async () => {
                    await axios
                        .get(`${env}${url}`)
                        .then(async (response: any) => {
                            dispatch(Actions.OfsshoreWindPageAction(response?.data?.data?.attributes))
                        })
                        .catch((error: any) => {
                            console.log("error", error);
                        });
                })()

            }, 0);


        }


        window.scrollTo({
            top: 0,
            left: 1,
        });



    }, [dispatch])

    const handleColon = (node: string) => {
        const colonExists = node?.includes(":") ? node?.split(":") : null
        if (colonExists) {
            return <div><span className='font-bold'>{colonExists[0]} :</span> {colonExists[1]} </div>

        } else {
            console.log("colnexidts", colonExists)
            return node
        }



    }



    return (
        <>

            <BargePageIntro BargePageIntro={OffshoreWind?.Intro} />
            <div className='grid md:grid-cols-2 gap-5  text-black-75 font-custom px-5 py-7 lg:px-10 xl:px-20 lg:py-14 '>
                {OffshoreWind?.OffshoreCards?.map((node: any, index: number) => {
                    return <div key={index + node?.Title ?? node?.Description1} className={`${node?.Title.toLowerCase() === "conclusion" && "col-span-full"} bg-[#f1f1f1]  p-5 rounded-md font-custom`}>
                        {console.log(node?.Description2?.split(":"))}
                        {node?.Title &&
                            <div className='font-bold text-lg md:text-xl text-[#DBA777]'>
                                {node?.Title}
                            </div>}
                        {node?.Description1 &&
                            <div className='pt-2'>
                                {node?.Description1 && handleColon(node?.Description1)}
                            </div>
                        }
                        {node?.Description2 &&
                            <div className='pt-2'>
                                {node?.Description2 && handleColon(node?.Description2)}
                            </div>
                        }
                        {node?.Description3 &&
                            <div className='pt-2'>
                                {node?.Description3 && handleColon(node?.Description3)}
                            </div>
                        }
                        {node?.Description4 &&
                            <div className={`pt-2 `}>
                                {node?.Description4 && handleColon(node?.Description4)}
                            </div>
                        }
                    </div>
                })}

            </div>
            <ContactUsSection ContactUsSection={OffshoreWind?.ConnectSection} />


        </>

    )
}

export default OffshoreWindpage