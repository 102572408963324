import React, { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { env } from '../config'
import axios from 'axios'
import * as Actions from "../redux/actions"
import Loader from '../components/Loader'
const IoTDevelopmentIntro = React.lazy(() => import('../components/IoTDevelopmentIntro'))
const IotDevelopmentPartnersSection = React.lazy(() => import('../components/IotDevelopmentPartnersSection'))
const IotDevelopmentUseCasesSection = React.lazy(() => import('../components/IotDevelopmentUseCasesSection'))
const IotDevelopmentProjects = React.lazy(() => import('../components/IotDevelopmentProjects'))




function IoTdDevelopmentPage() {

    const IotDevelopmentData: any = useSelector((state: any) => state?.Page?.IotDevelopmentPageContent)


    const dispatch = useDispatch()

    let url = "/api/io-t-development-page?populate=deep"
    let action = Actions.IotDevelopmentPageAction






    useEffect(() => {
        if (Object.keys(IotDevelopmentData).length === 0) {
            setTimeout(() => {
                (async () => {
                    await axios.get(`${env}${url}`)
                        .then(function (response) {
                            console.log(response.data.data.attributes);
                            dispatch(action(response?.data?.data?.attributes))

                        })
                        .catch((error: any) => {
                            console.log("error", error);
                        });
                })()
            }, 0);
        }

        window.scrollTo({
            top: 0,
            left: 1,
            behavior: 'smooth'
        });





    }, [dispatch])

    console.log("jjj", IotDevelopmentData?.IoTDevelopmentPageIntro)
    return (
        <div>
            <Suspense fallback={<Loader />}>
                <IoTDevelopmentIntro IoTDevelopmentPageIntro={IotDevelopmentData?.IoTDevelopmentPageIntro} ServicesCard={IotDevelopmentData?.IotServices?.ServicesCard} />
                <IotDevelopmentPartnersSection IoTPartners={IotDevelopmentData?.IoTPartners} />
                <IotDevelopmentUseCasesSection IoTUseCasesCards={IotDevelopmentData?.IoTUseCasesCards} />
                <IotDevelopmentProjects IotProjects={IotDevelopmentData?.IotProjects} />
            </Suspense>


        </div>
    )
}

export default IoTdDevelopmentPage