import axios from 'axios';
import React, { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { env } from '../config';
import * as Actions from "../redux/actions"
import Loader from '../components/Loader';
const BargePageIntro = React.lazy(() => import('../components/BargePageIntro'))
const BargeCardsSection = React.lazy(() => import('../components/BargeCardsSection'))
const ContactUsSection = React.lazy(() => import('../components/ContactUsSection'))


function SafetyAndMonitoringForBargesPage() {
    const dispatch = useDispatch()


    const getBargeData = () => {
        axios.get(`${env}/api/safety-and-monitoring-for-barges-page-content?populate=deep`)
            .then(function (response) {
                console.log("kkk", response?.data?.data?.attributes);
                dispatch(Actions.BargePageAction(response?.data?.data?.attributes))

            })
    }

    const BargeData: any = useSelector((state: any) => state?.Page?.BargePageContent)


    useEffect(() => {
        if (Object.keys(BargeData).length === 0) {
            getBargeData();
        }
        window.scrollTo({
            top: 0,
            left: 1,
            behavior: 'smooth'
        });

    }, [])














    console.log("BargeData", BargeData?.ContactUs)


    return (
        <div>
            <Suspense fallback={<Loader />}>

                <BargePageIntro BargePageIntro={BargeData?.SafetyAndMonitoringForBargesIntro} />
                {BargeData?.SafetyAndMonitoringForBargesCards ?
                    <BargeCardsSection BargeCards={BargeData?.SafetyAndMonitoringForBargesCards} />

                    : null
                }
                <ContactUsSection ContactUsSection={BargeData?.ContactUs} />
            </Suspense>

        </div>
    )
}

export default SafetyAndMonitoringForBargesPage