import React, { useState } from 'react'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { env } from '../config';
import { useNavigate } from 'react-router';

function CareerImageSliderSection(props: any) {
    const navigate = useNavigate()
    const { CareersPageImageSliderSection } = props;

    console.log(CareersPageImageSliderSection?.ImageSlide[0])

    const [hideTitle, sethideTitle] = useState<any>()








    return (
        <div className='pb-14 hidden'>
            <CarouselProvider
                naturalSlideWidth={10}
                naturalSlideHeight={10}
                totalSlides={CareersPageImageSliderSection?.ImageSlide?.length}
                orientation={"horizontal"}
                className="hidden lg:block "
                isPlaying={true}
                playDirection={"forward"}
                interval={4000}
                step={0.5}
                touchEnabled={true}
                visibleSlides={3.5}



            // top-[50%] left-[40%]


            >
                <Slider className=''>

                    {CareersPageImageSliderSection?.ImageSlide?.map((node: any, i: any) => (

                        <Slide index={i} key={i} className=" flex justify-center  items-center text-xl border overflow-hidden">

                            <img className='w-full h-full relative ' src={`${env}${node?.Image?.data?.attributes?.url}`} alt="none" />
                            <div className='divWithoutHover opacity-100  w-full h-full absolute top-0 text-white font-custom font-bold text-2xl'>
                                {hideTitle === i ? null :
                                    <div className='absolute top-[50%] left-[40%]'>
                                        {node?.Title}

                                    </div>}
                            </div>
                            <div className='  duration-500   top-[1px]   absolute opacity-0 hover:opacity-100 text-white  w-full h-full '>
                                <div onMouseEnter={() => { sethideTitle(i) }} onMouseLeave={() => { sethideTitle(null) }} className='mainHoverDiv relative h-full  top-[0]  '>
                                    <div className='secondaryHoverDiv h-full w-full flex flex-col items-center justify-center    p-6  absolute  left-[50%] right-[50%] -translate-x-[50%] top-[1000px]  duration-700 '>
                                        <div className=' font-bold font-custom text-center  pb-3  mx-auto  lg:text-4xl  border-b  border-[#FFFFFF]'>
                                            {node?.Title}
                                        </div>
                                        <div className='lg:mt-[10%] xl:mt-[10%]'>
                                            <button onClick={() => node?.title === "Ethnic Day" ? console.log("aoufnaiun") : navigate("/life@netstratum/ethnicday")} className='  border-[#808285] border text-[#808285] hover:bg-[#8A8A8A] hover:text-white  text-sm lg:text-base font-custom  rounded-[50px] bg-white px-2 py-1'>View More</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slide>
                    ))
                    }
                </Slider>
            </CarouselProvider>

            <CarouselProvider
                naturalSlideWidth={10}
                naturalSlideHeight={10}
                totalSlides={CareersPageImageSliderSection?.ImageSlide?.length}
                orientation={"horizontal"}
                className=" hidden md:block lg:hidden"
                isPlaying={true}
                playDirection={"forward"}
                interval={4000}
                step={0.5}
                touchEnabled={true}
                visibleSlides={2.5}
                infinite={true}




            // md:text-base text-sm lg:text-lg


            >
                <Slider className=''>
                    {CareersPageImageSliderSection?.ImageSlide?.map((node: any, i: any) => (
                        <Slide index={i} key={i} className="bg-slate-500 flex justify-center  items-center text-xl border overflow-hidden">
                            <img className='w-full h-full relative top-0 divWithoutHoverMobile  ' src={`${env}${node?.Image?.data?.attributes?.url}`} alt="none" />
                            <div className='divWithoutHoverMobile flex flex-col justify-center h-full  w-full text-white p-6   absolute top-0 bg-sky-600 duration-700 '>
                                <div className=' font-bold font-custom text-center text-3xl pb-3 w-max mx-auto border-b  border-[#FFFFFF] '>
                                    Sulaiman
                                </div>

                                <div className='mt-[20%] flex justify-center'>
                                    <button className='  border-[#808285] border text-[#808285] hover:bg-[#8A8A8A] hover:text-white  text-sm font-custom   rounded-[50px] bg-white px-3 py-2'>View More</button>
                                </div>
                            </div>
                        </Slide>
                        // top-[90%] hover:
                    ))
                    }
                </Slider>
            </CarouselProvider>
            <CarouselProvider
                naturalSlideWidth={10}
                naturalSlideHeight={10}
                totalSlides={CareersPageImageSliderSection?.ImageSlide?.length}
                orientation={"horizontal"}
                className=" md:hidden"
                isPlaying={true}
                playDirection={"forward"}
                interval={3000}
                step={0.5}
                touchEnabled={true}
                visibleSlides={1}
                infinite={true}
            >
                <Slider className=''>
                    {CareersPageImageSliderSection?.ImageSlide?.map((node: any, i: any) => (
                        <Slide index={i} key={i} className="bg-slate-500 flex justify-center  items-center text-xl border overflow-hidden">

                            <img className='w-full h-full relative divWithoutHoverMobile  ' src={`${env}${node?.Image?.data?.attributes?.url}`} alt="none" />
                            <div className='divWithoutHoverMobile flex flex-col justify-center h-full  w-full text-white p-6  absolute top-0 duration-700 '>
                                <div className=' font-bold font-custom text-center text-3xl pb-3 w-max mx-auto border-b  border-[#FFFFFF] '>
                                    {node?.Title}
                                </div>

                                <div className='mt-[20%] flex justify-center'>
                                    <button className='   text-sm font-custom  border-[#808285] border text-[#808285] hover:bg-[#8A8A8A] hover:text-white  rounded-[50px] bg-white px-3 py-2'>View More</button>
                                </div>
                            </div>
                        </Slide>
                        // top-[90%] hover:
                    ))
                    }
                </Slider>
            </CarouselProvider>
        </div >
    )
}

export default CareerImageSliderSection



