import React, { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { env } from '../config'
import * as Actions from "../redux/actions"
import Loader from '../components/Loader'
const ProductDevelopmentIntroCard = React.lazy(() => import('../components/ProductDevelopmentIntroCard'))
const CloudSolutionsCards = React.lazy(() => import('../components/CloudSolutionsCards'))
const CloudSolutionsProviders = React.lazy(() => import('../components/CloudSolutionsProviders'))
const ClousSolutionDescriptionSection = React.lazy(() => import('../components/ClousSolutionDescriptionSection'))
const ContactUsSection = React.lazy(() => import('../components/ContactUsSection'))



function CloudSolutionsPage() {
    const CloudSolutionsData: any = useSelector((state: any) => state?.Page?.CloudSolutionsPageContent)

    const dispatch = useDispatch()

    let url = "/api/cloud-solutions-page-content?populate=deep"
    let action = Actions.CloudSolutionspageAction




    useEffect(() => {
        if (Object.keys(CloudSolutionsData).length === 0) {
            setTimeout(() => {
                (async () => {
                    await axios.get(`${env}${url}`)
                        .then(function (response) {
                            console.log(response.data.data.attributes);
                            dispatch(action(response?.data?.data?.attributes))

                        })
                        .catch((error: any) => {
                            console.log("error", error);
                        });
                })()
            }, 0);
        }
        window.scrollTo({
            top: 0,
            left: 1,
            behavior: 'smooth'
        });
    }, [dispatch])
    return (
        <div>
            <Suspense fallback={<Loader />}>

                <ProductDevelopmentIntroCard
                    Title1={CloudSolutionsData?.Intro?.Title1}
                    Title2={CloudSolutionsData?.Intro?.Title2}
                    Description1={CloudSolutionsData?.Intro?.Description1}
                    url={CloudSolutionsData?.Intro?.Image?.data?.attributes?.url}
                    imgPosition="mx-auto"
                    altText="Cloud Solutions"
                    ButtonText={CloudSolutionsData?.Intro?.ButtonText}

                />

                <CloudSolutionsCards CardsSection={CloudSolutionsData?.CardsSection} Title={CloudSolutionsData?.CardsSection?.Title} />

                <CloudSolutionsProviders ProductArraySection={CloudSolutionsData?.SolutionProviderSection} />

                <ClousSolutionDescriptionSection DescriptionSection={CloudSolutionsData?.DescriptionSection} />

                <ContactUsSection ContactUsSection={CloudSolutionsData?.ContactUs} />
            </Suspense>

        </div>
    )
}

export default CloudSolutionsPage