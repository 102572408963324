import React from 'react'
import { env } from '../config';
import { useNavigate } from 'react-router';

const NcsUseCases = (props: any) => {
    const { useCases } = props;

    const navigate = useNavigate()
    const checkRouteAndNavigate = (title: string) => {
        console.log("tirlweee", title.toLowerCase().trim().split(" ")[0] + "/" + title.toLowerCase().trim().split(" ").splice(1, 2).join(""))

        navigate(`${title.toLowerCase().trim().split(" ").splice(1, 2).join("")}`)
    }
    return (
        <div id="usecases" className='px-5 md:px-10 lg:px-20 py-14 font-custom text-black-75'>
            <div className='flex flex-col  items-center gap-[10px]'>
                <div className='text-2xl md:text-4xl lg:text-5xl font-bold '>
                    {useCases?.product}
                </div>
                <div className='text-center text-base'>
                    {useCases?.description}
                </div>
            </div>
            <div className=' lg:flex flex-wrap justify-center gap-[10px] mt-12'>
                {useCases?.ProductCard?.map((node: any) => {
                    return (
                        <div key={node?.title} className='mb-[25px] lg:relative p-3 lg:flex flex-col just border-[0.5px] border-[#8A8A8A] md:basis-[300px] lg:basis-[400px] xl:basis-[500px]   rounded-[10px]'>
                            <img className=' pt-[10px] rounded-t-[10px]  lg:h-1/2 mx-auto' src={`${env}${node?.icon?.data?.attributes?.url}`} alt={node?.title} />
                            <div className='flex justify-center lg:justify-start'>
                                <div className='text-2xl font-custom font-bold  w-max m-6 pb-3 border-b border-black-75'>
                                    {node?.title}
                                </div>
                            </div>
                            <div className='px-6 text-center lg:text-left'>
                                {node?.description}
                            </div>
                            {/* {node?.ButtonText && */}
                            <div className=' mt-5 lg:mt-auto mb-5 lg:px-6 text-[#797979] flex  justify-center lg:justify-start '>
                                <button onClick={() => checkRouteAndNavigate(node?.title)} className=' rounded-[50px] px-6 py-2 border-[#808285] border text-[#808285] hover:bg-[#8A8A8A] hover:text-white '>View More</button>

                            </div>
                            {/* } */}

                        </div>
                    )
                })}
            </div>
        </div>

    )
}

export default NcsUseCases