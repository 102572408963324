import React, { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { env } from '../config'
import * as Actions from "../redux/actions"
import Loader from '../components/Loader'
const ContactIntro = React.lazy(() => import('../components/ContactIntro'))
const ContactMapsSection = React.lazy(() => import('../components/ContactMapsSection'))
const ContactGetInTouchSection = React.lazy(() => import('../components/ContactGetInTouchSection'))



function ContactPage() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 1,
            behavior: 'smooth'
        });

    }, [])

    const OfficeListData: any = useSelector((state: any) => state?.Page?.OfficeListContent)
    const ContactPageData: any = useSelector((state: any) => state?.Page?.ContactPageContent)

    console.log("ConactPageData?.ContactPageIntro", OfficeListData)




    const dispatch = useDispatch()

    let url = "/api/Offices?populate=deep"
    let action = Actions.OfficeListAction
    let url2 = "/api/contact-page-content?populate=deep"
    let action2 = Actions.ContactPageAction






    useEffect(() => {
        if (Object.keys(OfficeListData).length === 0) {
            setTimeout(() => {
                (async () => {
                    await axios.get(`${env}${url}`)
                        .then(function (response) {
                            console.log(response?.data?.data);
                            dispatch(action(response?.data?.data))

                        })
                        .catch((error: any) => {
                            console.log("error", error);
                        });
                })()
            }, 0);
        }
        if (Object.keys(ContactPageData).length === 0) {
            setTimeout(() => {
                (async () => {
                    await axios.get(`${env}${url2}`)
                        .then(function (response) {
                            console.log(response.data.data.attributes);
                            dispatch(action2(response?.data?.data?.attributes))

                        })
                        .catch((error: any) => {
                            console.log("error", error);
                        });
                })()
            }, 0);
        }







    }, [dispatch])



    return (
        <>

            <Suspense fallback={<Loader />}>
                <ContactIntro ContactPageIntro={ContactPageData?.ContactPageIntro} />
                <ContactMapsSection OfficeListData={OfficeListData} />
                <ContactGetInTouchSection />
            </Suspense>
        </>
    )
}

export default ContactPage