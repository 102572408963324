import React, { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SmartEnergySmartCity from '../components/SmartEnergySmartCity'
import SmartStreetLightSmartCity from '../components/SmartStreetLightSmartCity'
import SmartTrafficSmartCity from '../components/SmartTrafficSmartCity'
import SmartAirQualitySmartCity from '../SmartAirQualitySmartCity'
import SmartWasteSmartCity from '../SmartWasteSmartCity'
import Loader from '../components/Loader'
import axios from 'axios'
import { env } from '../config'
import * as Actions from "../redux/actions"
const SmartCityIntro = React.lazy(() => import('../components/SmartCityIntro'))
const AgroTechConnectSection = React.lazy(() => import('../components/AgroTechConnectSection'))




function SmartCityPage() {
    const SmartCityData: any = useSelector((state: any) => state?.Page?.SmartCityPageContent)
    const dispatch = useDispatch()
    // const [isLoading, setisLoading] = useState(true)

    let url = "/api/smart-city-page?populate=deep"
    let action = Actions.SmartCityPageAction


    // const handleLoading = () => {
    //     setisLoading(false);
    // }


    // useEffect(() => {
    //     if (document.readyState === "complete") {
    //         setisLoading(false);
    //     } else {
    //         window.scrollTo({
    //             top: 0,
    //             left: 1,
    //             behavior: 'smooth'
    //         });
    //         window.addEventListener('load', handleLoading);
    //         return () => document.removeEventListener('load', handleLoading);
    //     }



    // }, [])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 1,
            behavior: 'smooth'
        });
        if (Object.keys(SmartCityData).length === 0) {
            setTimeout(() => {
                (async () => {
                    await axios.get(`${env}${url}`)
                        .then(function (response) {
                            console.log(response.data.data.attributes);
                            dispatch(action(response?.data?.data?.attributes))

                        })
                        .catch((error: any) => {
                            console.log("error", error);
                        });
                })()
            }, 0);
        }







    }, [dispatch])


    return (
        <Suspense fallback={<Loader />}>
            {SmartCityData?.SmartCityCard ?
                <SmartCityIntro SmartCityCard={SmartCityData?.SmartCityCard} />
                : null}
            <AgroTechConnectSection AgrotechConnectSection={SmartCityData?.SmartCityConnect} />
        </Suspense>
    )
}

export default SmartCityPage