import React from 'react'
import { env } from '../config'

function WikipeedikaIntro(props: any) {
    const { WikipeedikaIntro } = props
    return (
        <div style={{ backgroundImage: `url(${env}${WikipeedikaIntro?.backgroundImage?.data?.attributes?.url})` }} className={` bg-no-repeat bg-cover text-black-75  h-96 md:h-[400px] lg:h-[650px]   px-5 py-7 lg:px-10 xl:px-20 lg:py-14 bg-center flex flex-col  justify-center items-center gap-5`}>
            <img src={`${env}${WikipeedikaIntro?.Title?.data?.attributes?.url}`} alt="Title" />
            <div className='lg:text-lg text-base text-center  mt-5 font-custom xl:w-[50%]'>
                {WikipeedikaIntro?.Description}
            </div>
        </div>
    )
}

export default WikipeedikaIntro