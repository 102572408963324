import React from 'react'

const NcsFeatures = (props: any) => {
    const { bullets } = props;
    return (
        <div className='bg-white px-5 md:px-10 lg:px-20  text-black-75 py-7 lg:py-16 xl:py-24 '>
            <div className='text-2xl md:text-4xl lg:text-5xl font-custom font-bold pb-9 flex justify-center mx-auto text-center'>
                Features
            </div>
            <div className='flex justify-center items-center gap-8 flex-wrap'>
                {bullets?.map((node: { bullet: string }, index: number) => {
                    return (
                        <div key={index + node?.bullet} className='py-2 px-4 md:text-lg flex items-center gap-3 bg-[#ffbb6d1f] rounded-[50px]'>
                            <span className='rounded-[50%] inline-block bg-[#FFBB6D] w-3 h-3'>
                            </span>  {node?.bullet}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default NcsFeatures