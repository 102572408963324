import React, { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DigitalTransformationPointsSection from '../components/DigitalTransformationPointsSection'
import * as Actions from "../redux/actions"
import axios from 'axios'
import { env } from '../config'
import Loader from '../components/Loader'
const DigitalTranfromationPageIntro = React.lazy(() => import('../components/DigitalTranfromationPageIntro'))
const DigitalTransformationdescription = React.lazy(() => import('../components/DigitalTransformationdescription'))
const DigitalTranformationTechCards = React.lazy(() => import('../components/DigitalTranformationTechCards'))
const DigitalTranformationAdv = React.lazy(() => import('../components/DigitalTranformationAdv'))
const ContactUsSection = React.lazy(() => import('../components/ContactUsSection'))



function DigitalTransfromationPage() {
    const DigitalTranformationData: any = useSelector((state: any) => state?.Page?.DigitalTransfromationPageContent)

    const dispatch = useDispatch()


    let url = "/api/digital-transformation-page-content?populate=deep"
    let action = Actions.DigitalTransformationpageAction




    useEffect(() => {
        if (Object.keys(DigitalTranformationData).length === 0) {
            setTimeout(() => {
                (async () => {
                    await axios.get(`${env}${url}`)
                        .then(function (response) {
                            console.log(response.data.data.attributes);
                            dispatch(action(response?.data?.data?.attributes))

                        })
                        .catch((error: any) => {
                            console.log("error", error);
                        });
                })()
            }, 0);
        }
        window.scrollTo({
            top: 0,
            left: 1,
            behavior: 'smooth'
        });
    }, [dispatch])



    console.log("DigitalTranformationData", DigitalTranformationData?.DigitalTransformationPointsSection)

    return (
        <div>
            <Suspense fallback={<Loader />}>

                <DigitalTranfromationPageIntro DigitalTransformationPageIntro={DigitalTranformationData?.DigitalTransformationPageIntro} />
                <DigitalTransformationdescription Cards={DigitalTranformationData?.DigitalTransformationDescription?.DigitalTransformationCards} DigitalTransformationDescription={DigitalTranformationData?.DigitalTransformationDescription} />
                <DigitalTranformationTechCards DigitalTransformationTechCards={DigitalTranformationData?.DigitalTransformationTechCards} />
                <DigitalTranformationAdv DigitalTransformationCardsSection={DigitalTranformationData?.DigitalTransformationCardsSection} />
                <ContactUsSection ContactUsSection={DigitalTranformationData?.Contact} />
            </Suspense>


        </div>
    )
}

export default DigitalTransfromationPage