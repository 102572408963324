import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from "../redux/actions"
import axios from 'axios';
import { env } from '../config';
import ProductDevelopmentIntroCard from '../components/ProductDevelopmentIntroCard';
import NcsAdvantages from '../components/NcsAdvantages';
import NcsUseCases from '../components/NcsUseCases';
import ContactUsSection from '../components/ContactUsSection';
import Loader from '../components/Loader';

const NcsPage = () => {

    const NcsData: any = useSelector((state: any) => state?.Page?.NcsPageContent)
    console.log("nsep", NcsData);


    const IndividualPageData: any = useSelector((state: any) => state?.Page?.NcsIndividualPageContent)



    const dispatch = useDispatch()

    let url = "/api/ncs-page-content?populate=deep"
    let action = Actions.NCSpageAction
    const action1 = Actions.NCSIndividualpageAction
    const url1 = "/api/ncs-individual-pages?populate=deep"


    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 1,
            behavior: 'smooth'
        });
        if (Object.keys(NcsData).length === 0) {
            setTimeout(() => {
                (async () => {
                    await axios.get(`${env}${url}`)
                        .then(function (response) {
                            console.log(response.data.data.attributes);
                            dispatch(action(response?.data?.data?.attributes))

                        })
                        .catch((error: any) => {
                            console.log("error", error);
                        });
                })()
            }, 0);
        }
        if (IndividualPageData?.length === 0) {
            setTimeout(() => {
                (async () => {
                    await axios.get(`${env}${url1}`)
                        .then(function (response) {
                            console.log(response.data.data);
                            dispatch(action1(response?.data?.data))

                        })
                        .catch((error: any) => {
                            console.log("error", error);
                        });
                })()
            }, 0);
        }







    }, [dispatch])



    return (
        Object.keys(NcsData).length !== 0 ?
            <>
                <ProductDevelopmentIntroCard
                    Title1={NcsData?.Intro?.Title1}
                    Title2={NcsData?.Intro?.Title2}
                    Description1={NcsData?.Intro?.Description1}
                    url={NcsData?.Intro?.Image?.data?.attributes?.url}
                    imgPosition="mx-auto"
                    altText="NCS page hero image"
                    ButtonText={NcsData?.Intro?.ButtonText}
                />
                <NcsUseCases useCases={NcsData?.usecases} />
                <NcsAdvantages advantages={NcsData?.advantages} />
                <ContactUsSection ContactUsSection={NcsData?.Contact} />


            </> : <Loader />

    )
}

export default NcsPage