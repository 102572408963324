import axios from 'axios'
import React, { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CareerImageSliderHeading from '../components/CareerImageSliderHeading'
import { env } from '../config'
import *as Actions from "../redux/actions"
import Loader from '../components/Loader'
import CareerImageSliderSection from '../components/CareerImageSliderSection'
const CareerIntro = React.lazy(() => import('../components/CareerIntro'))
const CareerCardsSection1 = React.lazy(() => import('../components/CareerCardsSection1'))
const CareerCardsSection2 = React.lazy(() => import('../components/CareerCardsSection2'))
// const CareerImageSliderSection = React.lazy(() => import('../components/CareerImageSliderSection'))
const CareerApplication = React.lazy(() => import('../components/CareerApplication'))





function CareerPage() {
    const dispatch = useDispatch()

    const CareersData: any = useSelector((state: any) => state?.Page?.CareerPageContent)
    console.log("HOOMMRR", CareersData);

    const JobList: any = useSelector((state: any) => state?.Page?.CareerIndividualarray)
    console.log("Joblist", JobList);




    let url = "/api/job-lists?populate=deep"
    let action = Actions.CareerIndividualAction

    let url1 = "/api/career-page-content?populate=deep"
    let action1 = Actions.CareerPageAction

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 1,
            behavior: 'smooth'
        });
        if (Object.keys(JobList).length === 0) {
            setTimeout(() => {
                (async () => {
                    await axios.get(`${env}${url}`)
                        .then(function (response) {
                            console.log(response?.data?.data);
                            dispatch(action(response?.data?.data))

                        })
                        .catch((error: any) => {
                            console.log("error", error);
                        });
                })()
            }, 0);
        }
        if (Object.keys(CareersData).length === 0) {
            setTimeout(() => {
                (async () => {
                    await axios.get(`${env}${url1}`)
                        .then(function (response) {
                            console.log(response.data.data.attributes);
                            dispatch(action1(response?.data?.data?.attributes))

                        })
                        .catch((error: any) => {
                            console.log("error", error);
                        });
                })()
            }, 0);
        }
        window.scrollTo({
            top: 0,
            left: 1,
            behavior: 'smooth'
        });
    }, [dispatch])






    return (
        <div>
            <Suspense fallback={<Loader />}>
                <CareerIntro CareersPageIntro={CareersData?.CareersPageIntro} />
                <CareerCardsSection1 CareersPassionSection={CareersData?.CareersPassionSection} />
                <CareerCardsSection2 CareersPassionSection={CareersData?.CareersPassionSection} />
                {/* <CareerImageSliderHeading CareersPageImageSliderSection={CareersData?.CareersPageImageSliderSection?.TitleContent} /> */}
                {/* <Suspense fallback={<></>}> */}
                <CareerImageSliderSection CareersPageImageSliderSection={CareersData?.CareersPageImageSliderSection} />
                {/* </Suspense> */}
                <CareerApplication JobList={JobList} CareersPageApplicationSection={CareersData?.CareersPageApplicationSection} />
            </Suspense>

        </div>
    )
}

export default CareerPage


