import React, { useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { irBlack as theme } from "react-syntax-highlighter/dist/esm/styles/hljs";

function SDKInstall(props: any) {
    const { Installation } = props;
    const [deploymentChoice, setDeploymentChoice] = useState<any>("web");
    return (
        <div className="w-full bg-[#404041] py-10 xl:py-20">
            <div className=" w-full md::w-[95%] xl:w-[85%] 2xl:w-3/4 mx-auto  p-1 flex flex-col ">
                <div
                    className="text-xl md:text-2xl lg:text-4xl xl:text-5xl font-bold p-1 xl:leading-[60px] text-white  text-center 2xl:text-left  xl:w-2/5"
                    data-aos="fade-down"
                >
                    Easy Installation, Faster Deployment
                </div>
                <div
                    className=" text-white text-center mx-auto xl:mx-0 font-body xl:text-left md:w-[70%] xl:w-1/3 p-1 my-3 md::my-8 text-sm sm:text-sm md:text-md lg:text-lg  xl:text-xl font-light "
                    data-aos="fade-down"
                >
                    Whether you want to build an entire application or wants to add additional functionalities, NCS SDKs are easy to install and integrate with extensive documenation and tutorials
                </div>
                <div className="bg-[#2E2E2E] rounded-xl w-auto flex-col-reverse  flex lg:flex-row p-2 lg:p-4 text-xs md:text-sm xl:text-md">
                    <div
                        className="lg:w-1/2 bg-white  sm:h-auto  lg:p-2 rounded-xl flex"
                        data-aos="zoom-in"
                    >
                        <div className=" flex  flex-col p-1 h-fit  w-full">
                            <div className="flex justify-around items-center flex-row p-1 text-center text-xs md:text-sm xl:text-md">
                                <div
                                    className={` w-1/2 mx-1 py-2 cursor-pointer font-body  ${deploymentChoice === "web"
                                        ? "border-b-4 border-[#F7931F] font-bold"
                                        : ""
                                        }`}
                                    onClick={() => setDeploymentChoice("web")}
                                >
                                    web
                                </div>
                                <div
                                    className={`w-1/2 mx-1 py-2 cursor-pointer font-body ${deploymentChoice === "native"
                                        ? "border-b-4 border-[#F7931F] font-bold"
                                        : ""
                                        }`}
                                    onClick={() => setDeploymentChoice("native")}
                                >
                                    react-native
                                </div>
                            </div>

                            {deploymentChoice === "web" ? (
                                <div className=" mt-1 flex flex-col p-2 h-[250px] md:h-80 w-full text-xs md:text-sm xl:text-md">
                                    <div>install the NCS SDK</div>
                                    <div className="bg-black text-[#9C9C9C] rounded-md p-3  font-code ">
                                        <SyntaxHighlighter language="javascript" style={theme}>
                                            npm install hdmeet
                                        </SyntaxHighlighter>
                                    </div>
                                    <div>configure the SDK</div>
                                    <div className="bg-black  rounded-md p-3 flex-auto text-[#9C9C9C] font-code">
                                        <SyntaxHighlighter language="javascript" style={theme}>
                                            {`import { MeetingSession } from "hdmeet";
function App() {
 const meetingSession = 
 new MeetingSession(apiKey, logLevel, environmentLevel);
 }`}
                                        </SyntaxHighlighter>
                                    </div>
                                </div>
                            ) : (
                                <div className=" mt-1 flex flex-col p-2 h-[250px] md:h-80">
                                    <div>install the NCS Mobile SDK</div>
                                    <div className="bg-black text-[#9C9C9C] rounded-md p-3 font-code">
                                        <SyntaxHighlighter language="javascript" style={theme}>
                                            npm install react-native-hdmeet
                                        </SyntaxHighlighter>
                                    </div>
                                    <div>configure the SDK</div>
                                    <div className="bg-black text-[#9C9C9C] rounded-md p-3 flex-auto font-code">
                                        <SyntaxHighlighter language="javascript" style={theme}>
                                            {`import { MeetingSession } from 'react-native-hdmeet';
function App() {
 const meetingSession = 
 new MeetingSession(apiKey, logLevel, environmentLevel);
 }`}
                                        </SyntaxHighlighter>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        className="lg:w-1/2 rounded-xl lg:ml-2 flex  justify-center item center"
                        data-aos="zoom-in"
                    >
                        <iframe
                            title="Youtube link"
                            className=" rounded-xl mb-3 lg:my-auto w-full h-full md:h-[300px] lg:h-full"
                            // src="https://www.youtube.com/embed/tgbNymZ7vqY"
                            src={`https://www.youtube.com/embed/zkm5GKzYcmM`}
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SDKInstall;
