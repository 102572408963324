import React from 'react'
import IoTDevelopmentServicesCard from './IoTDevelopmentServicesCard';



const NcsAdvantages = (props: any) => {
    const { advantages } = props;
    return (
        <div>
            <div className='bg-[#212121] px-5 md:px-10 lg:px-20  text-white py-7 lg:py-16 xl:py-24 '>
                <div className='text-2xl md:text-4xl lg:text-5xl  font-custom  pb-9 flex justify-center w-1/2 mx-auto text-center'>
                    {advantages?.product}
                </div>
                <div className='text-lg  font-custom  pb-9 flex justify-center w-1/2 mx-auto text-center'>
                    {advantages?.subtitle}
                </div>
                <div className='flex flex-col  sm:flex-row flex-wrap justify-center gap-[10px] mb-3'>
                    {advantages?.ProductCard?.filter((node: any, index: number) => index < 4).map((element: any, index: number) => {
                        return (
                            <IoTDevelopmentServicesCard bg={`text-center ${index % 2 === 0 ? " bg-[#515151]" : "bg-[#2a2a2a] "}`} url={element?.icon?.data?.attributes?.url} title={element?.title} />
                        )
                    })}

                </div>
                <div className='flex flex-col sm:flex-row flex-wrap justify-center gap-[10px] '>
                    {advantages?.ProductCard?.filter((node: any, index: number) => index > 3).map((element: any, index: number) => {
                        return (
                            <IoTDevelopmentServicesCard bg={`text-center  ${index % 2 !== 0 ? " bg-[#515151]" : "bg-[#2a2a2a] "} `} url={element?.icon?.data?.attributes?.url} title={element?.title} />
                        )
                    })}

                </div>
            </div>
        </div>
    )
}

export default NcsAdvantages