import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import * as Actions from "../redux/actions"
import { env } from '../config';
import ProductDevelopmentIntroCard from '../components/ProductDevelopmentIntroCard';
import ContactUsSection from '../components/ContactUsSection';
import NcsIndividualAdvantage from '../components/NcsIndividualAdvantage';
import Loader from '../components/Loader';
import NcsFeatures from '../components/NcsFeatures';
import SDKInstall from '../components/NcsSdk';

const NcsIndividualPage = () => {
    const location = useLocation()
    const IndividualPageData: any = useSelector((state: any) => state?.Page?.NcsIndividualPageContent)

    const dispatch = useDispatch()
    const action = Actions.NCSIndividualpageAction
    const url = "/api/ncs-individual-pages?populate=deep"

    const [currentPage, setCurrentPage] = useState<any>({})

    const findCurrentPage = () => {
        const currentCopy = IndividualPageData?.filter((node: { attributes: { url: string } }) => node?.attributes?.url?.trim().toLowerCase() === location.pathname.split("/")[2])[0]
        console.log("asdknasdlkjnhal", currentCopy)
        setCurrentPage(currentCopy)

    }

    useMemo(() => {
        window.scrollTo({
            top: 0,
            left: 1,
            // behavior: 'smooth'
        });

        console.log("ioqenfodjnadshcb", IndividualPageData)
        console.log("asdjnakjsdn", location.pathname.split("/")[2])
        if (Object.keys(IndividualPageData)?.length === 0) {
            setTimeout(() => {
                (async () => {
                    await axios.get(`${env}${url}`)
                        .then(function (response) {
                            console.log(response?.data?.data);
                            dispatch(action(response?.data?.data))
                            findCurrentPage()
                        })
                        .catch((error: any) => {
                            console.log("error", error);
                        });
                })()
            }, 0);
        } else if (location.pathname && IndividualPageData) {
            findCurrentPage()

        }


    }, [location?.pathname, currentPage])




    return (
        currentPage ?
            <>
                <ProductDevelopmentIntroCard
                    Title1={currentPage?.attributes?.Intro?.Title1}
                    Title2={currentPage?.attributes?.Intro?.Title2}
                    Description1={currentPage?.attributes?.Intro?.Description1}
                    Description2={currentPage?.attributes?.Intro?.Description2}

                    url={currentPage?.attributes?.Intro?.Image?.data?.attributes?.url}
                    imgPosition="mx-auto"
                    altText="NCS page hero image"
                    ButtonText={currentPage?.attributes?.Intro?.ButtonText}
                />
                <NcsIndividualAdvantage title={currentPage?.attributes?.Features?.Title} Features={currentPage?.attributes?.Features} />

                <NcsFeatures bullets={currentPage?.attributes?.bullet} />
                {currentPage?.attributes?.url === "hdmeetsdk" &&
                    <SDKInstall />

                }
                <ContactUsSection ContactUsSection={currentPage?.attributes?.Contact} />

            </> :
            <Loader />
    )
}

export default NcsIndividualPage