import React from 'react'
import { useNavigate } from 'react-router'

export const Search = (props: any) => {
  const { addClass } = props;
  const navigate = useNavigate()
  return (
    <div className={`w-56 cursor-pointer  h-6 flex flex-row justify-between italic ${addClass}  `}>

      <p className=' font-custom text-black-75 px-1' onClick={() => { navigate("/life@netstratum") }}>#lifeatnetstratum</p>

    </div>
  )
}
