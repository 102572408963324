import React, { Suspense, useEffect, useState } from 'react'
import * as Actions from "../redux/actions"
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { env } from '../config'
import Loader from '../components/Loader'
import WikipeedikaIntro from "../components/WikipeedikaIntro"
import WikipeedikaGrid from "../components/WikipeedikaGrid"

// const WikipeedikaIntro = React.lazy(() => import('../components/WikipeedikaIntro'))
// const WikipeedikaGrid = React.lazy(() => import('../components/WikipeedikaGrid'))



function WikipeedikaPage() {

    const dispatch = useDispatch()



    const WikipeedikaPageData: any = useSelector((state: any) => state?.Page?.WikipeedikaPageContent)
    const WikipeedikaBlogData: any = useSelector((state: any) => state?.Page?.WikipeedikaBlogContent)
    const [loader, setLoader] = useState(false)

    let url = "/api/wikipeedika-page-content?populate=deep"
    let action = Actions.WikipeedikapageAction
    let url1 = "/api/wikipeedika-blogs?populate=deep"
    let action1 = Actions.WikipeedikaBlogContentAction



    useEffect(() => {

        window.scrollTo({
            top: 0,
            left: 1,
            behavior: 'smooth'
        });



        if (Object.keys(WikipeedikaPageData)?.length === 0) {
            setLoader(true)
            setTimeout(() => {
                (async () => {
                    await axios.get(`${env}${url}`)
                        .then(function (response) {
                            console.log(response.data.data.attributes);
                            dispatch(action(response?.data?.data?.attributes))
                            setLoader(false)

                        })
                        .catch((error: any) => {
                            console.log("errorjjjj", error);
                            // setLoader(false)

                        });
                })()
            }, 0);
        }
        if (Object.keys(WikipeedikaBlogData)?.length === 0) {
            setTimeout(() => {
                (async () => {
                    await axios.get(`${env}${url1}`)
                        .then(function (response) {
                            console.log(response)
                            dispatch(action1(response?.data?.data))

                        })
                        .catch((error: any) => {
                            console.log("error", error);
                        });
                })()
            }, 0);
        }
        window.scrollTo({
            top: 0,
            left: 1,
            behavior: 'smooth'
        });
    }, [dispatch])

    console.log("WikipeedikaPageData", WikipeedikaBlogData)



    return (
        loader ?
            <Loader />
            :
            <>
                <WikipeedikaIntro WikipeedikaIntro={WikipeedikaPageData?.WikipeedikaIntro} />
                <WikipeedikaGrid WikipeedikaBlogData={WikipeedikaBlogData} />
            </>
    )
}

export default WikipeedikaPage