import React from 'react'
import HeadingText from './HeadingText'
import { env } from '../config';

function DigitalTransformationdescription(props: any) {
    const { DigitalTransformationDescription, Cards } = props;
    return (
        <div id={`${DigitalTransformationDescription?.product?.toLowerCase()}`} className='text-black-75 font-custom px-5 py-7 lg:px-10 xl:px-20 lg:py-14 ' >
            <img className=' lg:hidden w-full h-auto' alt={DigitalTransformationDescription?.Title} src={`${env}${DigitalTransformationDescription?.Image?.data?.attributes?.url}`} />

            <div className='flex'>

                <img className='hidden lg:block ml-auto h-max my-auto' alt="not available" src={`${env}${DigitalTransformationDescription?.Image?.data?.attributes?.url}`} />
                <div className='xl:w-[60%] xl:ml-auto'>
                    <HeadingText
                        logo={DigitalTransformationDescription?.logo}
                        Heading={DigitalTransformationDescription?.product}
                        Body={DigitalTransformationDescription?.description}
                        bg=" p-4 mb-[30px] "
                    />

                    <div className='grid md:grid-cols-2    '>

                        {Cards?.map((node: any, index: number) => {
                            return (
                                <div key={index + node?.icon?.attributes?.url} className={`    p-4 flex flex-col justify-evenly md:items-center md:text-center lg:items-start lg:text-left gap-3   my-2`}>
                                    {node?.icon?.data?.attributes?.url &&
                                        <img alt="not available" className='w-12 h-12' src={`${env}${node?.icon?.data?.attributes?.url}`} />

                                    }
                                    <div className='font-bold font-custom  md:text-base '>
                                        {node?.title}
                                    </div>
                                    <div className='font-custom md:text-base text-base '>
                                        {node?.description}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DigitalTransformationdescription