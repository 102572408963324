import React from 'react'
import { env } from '../config'

function IoTDevelopmentServicesCard(props: any) {
    const { title, url, bg } = props;
    return (
        <div>
            <div className={`sm:w-[200px] sm:h-[200px] p-7 sm:p-0 ${bg} border border-white border-opacity-50 rounded-[5px] flex flex-col `}>
                {url &&
                    <div className='h-[60%] flex justify-center items-center w-full'>
                        <img src={`${env}${url}`} className='w-12 h-12 ' alt="not available" />

                    </div>

                }
                <div className='md:h-[40%] flex justify-center  '>
                    {title}

                </div>
            </div>
        </div>
    )
}

export default IoTDevelopmentServicesCard