import React from 'react'
import HrmnestCards from './HrmnestCards'
import HRMnestTitleSection from './HRMnestTitleSection';

function HRMnestSection(props: any) {
    const { product, bg = false, index = undefined } = props;
    console.log("hrmenstsEction", product)
    // id={`${product?.Title === "HRMNest" ? "HRMNest" : "paystratum"}`}
    console.log("producccccct", product?.product?.toLowerCase().includes(" ") ? product?.product?.toLowerCase().split(" ").join("") : product?.product?.toLowerCase())
    return (
        <div id={`${product?.product?.toLowerCase().includes(" ") ? product?.product?.toLowerCase().split(" ").join("") : product?.product?.toLowerCase()}`} className={`${bg ? "" : `bg-vosavixy`}   bg-cover bg-no-repeat`}>
            <HRMnestTitleSection
                Title1={product?.product}
                Description1={product?.description}
                logo={product?.logo}
                url={product?.Image?.data?.attributes?.url}
                imgPosition="mx-auto"
                altText="HRMNEST"
                link={product?.link}
                ButtonText={product?.buttonText}
                position={bg && index === 1 ? "flex-col-reverse flex lg:flex-row-reverse lg:justify-between" : undefined}
            />
            {product?.ProductCard.length > 0 &&
                <HrmnestCards title={product?.subtitle} Features={product?.ProductCard ? product?.ProductCard : product?.ProductCard} />

            }

        </div>
    )
}

export default HRMnestSection