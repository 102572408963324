import React, { useState } from 'react'
import WikipeedikaCardComponent from './WikipeedikaCardComponent';

function WikipeedikaGrid(props: any) {
    const { WikipeedikaBlogData } = props;
    let indexArray = Array.from({ length: Math.ceil(WikipeedikaBlogData?.length / 6) }, (value, index) => index + 1);

    const [activePage, setactivePage] = useState(1)



    return (
        <div className='text-black-75  px-5 py-7 lg:px-10 xl:px-20 lg:py-14 '>
            <div className='grid lg:grid-cols-3 gap-5'>
                {WikipeedikaBlogData?.sort((a: any, b: any) => {
                    a = a?.attributes?.Date.split('/').reverse().join('');
                    b = b?.attributes?.Date.split('/').reverse().join('');
                    return b > a ? 1 : b < a ? -1 : 0;
                }).filter((node: any, index: any) => {
                    return (index >= (6 * (activePage - 1))) && (index <= ((activePage * 5) + (activePage - 1)))
                }).map((node: any) => {
                    return <WikipeedikaCardComponent key={node?.attributes?.Date} node={node} />
                })}
            </div>
            <div className='w-max flex  mx-auto mt-14 gap-2 '>
                {activePage === 1 ? <svg className='cursor-not-allowed' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.5">
                        <rect width="32" height="32" rx="4" fill="#919EAB" />
                        <path d="M20.1602 11.41L15.5802 16L20.1602 20.59L18.7502 22L12.7502 16L18.7502 10L20.1602 11.41Z" fill="#C4CDD5" />
                    </g>
                </svg> : <svg onClick={() => { setactivePage(activePage - 1) }} role="button" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="white" />
                    <path d="M20.1602 11.41L15.5802 16L20.1602 20.59L18.7502 22L12.7502 16L18.7502 10L20.1602 11.41Z" fill="#C4CDD5" />
                    <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#DFE3E8" />
                </svg>}
                {indexArray?.map((node: any, index: number) => {
                    return (
                        <div onClick={() => { setactivePage(node) }} role="button" className={`w-[32px] border rounded-[5px] ${activePage === node ? "text-[#F7941E] border-[#F7941E]" : null}  h-[32px] flex justify-center items-center`}>
                            {node}
                        </div>
                    )
                })}
                {activePage === indexArray[indexArray.length - 1] ? <svg className='rotate-180 cursor-not-allowed' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.5">
                        <rect width="32" height="32" rx="4" fill="#919EAB" />
                        <path d="M20.1602 11.41L15.5802 16L20.1602 20.59L18.7502 22L12.7502 16L18.7502 10L20.1602 11.41Z" fill="#C4CDD5" />
                    </g>
                </svg> :

                    <svg onClick={() => { setactivePage(activePage + 1) }} role="button" className='rotate-180' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="white" />
                        <path d="M20.1602 11.41L15.5802 16L20.1602 20.59L18.7502 22L12.7502 16L18.7502 10L20.1602 11.41Z" fill="#C4CDD5" />
                        <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#DFE3E8" />
                    </svg>
                }
            </div>


        </div>
    )
}

export default WikipeedikaGrid