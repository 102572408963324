import React from 'react'

function Loader() {
    return (
        <div className='w-[100vw] h-[100vh] absolute top-0 left-0 flex justify-center items-center bg-white z-[1000]'>
            <div className="loader-11"></div>
        </div>
    )
}

export default Loader