import React from 'react'
import { env } from '../config';

function BargePageIntro(props: any) {
    const { BargePageIntro } = props;
    return (
        <div>
            <div className='relative flex flex-col-reverse lg:grid lg:grid-cols-2 place-items-center lg:my-9 text-black-75 font-custom '>
                <div className=' flex flex-col  p-3 text-black-75 mx-4 xl:w-9/12'>
                    <div className='text-2xl md:text-4xl lg:text-5xl font-bold  '>
                        {BargePageIntro?.Title}
                    </div>
                    <div className='text-left text-base mt-[10px] '>
                        {BargePageIntro?.Description}
                    </div>
                </div>
                <img className='mx-auto ' src={`${env}${BargePageIntro?.Image?.data?.attributes?.url}`} alt={BargePageIntro?.Title} />
            </div>
        </div>
    )
}

export default BargePageIntro