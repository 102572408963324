import React from 'react'
import { env } from '../config';

function HeadingText(props: any) {
    const { Heading, Body, position = '', padding, bg, logo } = props;
    return (
        <div className={`${bg}`}>
            {logo ?
                <img src={`${env}${logo?.data?.attributes?.url}`} alt="NCS Logo" className={`w-[150px] md:w-[220px] ${Heading?.toLowerCase() !== "fohik" && "px-5"} `} />
                :
                <div className={`text-2xl md:text-4xl lg:text-5xl ${position}  font-custom font-bold`}>
                    {Heading}
                </div>
            }


            <div className={`lg:text-lg md:text-base text-base ${padding}  font-custom  pt-3`}>
                {Body}
            </div>
        </div>
    )
}

export default HeadingText